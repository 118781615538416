@import 'src/assets/css/variables.scss';
.media-main-container {
  height: auto;
  display: block;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: $white-grey;
  @media (max-width: $tablet-grid) {
    max-width: 485px;
  }
  @media (max-width: $phone-grid) {
    width: 95%;
    .media-tab-text {
      padding: 10px 0;
    }
  }
}
.galleryHeaderContainer {
  width: 500px;
  height: auto;
}
.media-tab-container,
.row-list-title-container {
  background-color: transparent;
  position: relative;
  overflow-y: clip;
  &.chat {
    overflow: visible;
  }
  .tab-notification {
    position: relative;
    right: -8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $white-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
    bottom: 4px;
    .number {
      color: $color-black;
      font-size: 12px;
      font-family: $font-bold;
    }
    &.big {
      width: 28px;
      height: 28px;

      .number {
        font-size: 16px;
      }
    }
    @media (max-width: $tablet-grid) {
      background-color: $color-white;
      right: -5px;
      width: 20px;
      height: 20px;
      .number {
        font-size: 10px;
      }
      &.big {
        width: 28px;
        height: 28px;

        .number {
          font-size: 16px;
        }
      }
    }
  }
}
.media-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-black;
  cursor: pointer;
  font-size: 18px;
  font-family: $font-semibold;
  background-color: $color-white;
  border-radius: 13px;

  &.selected {
    margin: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 3px 13px 1px rgba(165, 173, 189, 0.7);
  }
  
  .media-tab-text-container {
    width: 100%;
    
    .tab-text {
      position: relative;
      width: fit-content;
      text-align: left;
    }
  }

  @media (max-width: $tablet-grid) {
    background-color: $color-blue-extra-light;
    &.selected {
      margin: 0 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 2px 8px 1px rgba(165, 173, 189, 0.7);
    }
  }

}

.gallery ,
.tab-right {
    .row-list-title-container {
        
        @media (max-width: 352px) {
            flex-direction: column;
            gap: 12px;
        }
    }
}

.gallery-photos-container {
  width: auto;
  height: auto;
  margin: auto;
  overflow: hidden;
}

.gallery-photos-scroll-view {
  padding: 20px 16px 32px;
  width: 100%;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 8px 8px;
  img {
    max-width: 100%;
    width: 100%;
    position: relative;
  }
  .gallery-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    min-height: 100px;
    background-color: white;
    &:hover {
      border: solid 4px $color-blue;
    }

    .overlay-image {
      width: 100%;
      height: 100%;
      background: transparent;
      position: absolute;
    }
    i.fa.fa-play-circle {
      position: absolute;
      font-size: 50px;
      width: 100%;
      color: $color-blue;
      &:hover,
      &:active,
      &:focus {
        color: black;
      }
    }
    .background-fa {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: white;
    }
  }

  @media (max-width: $tablet-grid) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.upload-button-container {
  color: $color-blue;
  font-size: 12px;
  font-family: $font-regular;
  text-align: center;
  min-height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(165, 173, 189, 0.75);
  background-image: linear-gradient(to bottom, white 50%, $white);
  white-space: nowrap;

  .icon-button {
    width: 12px;
    height: 12px;
  }
}

.document-list-container {
  width: 100%;
  text-align: left;

  .table-header-container {
    padding: 4px 12px 4px 12px;
    background-color: $color-grey-blue;
    color: white;
    font-family: $font-bold;
    font-size: 12px;
    min-height: 24px;
    border: none;
    border-radius: 4px;
    .t-header {
      min-height: 100%;
      line-height: 16px;
      padding: 0 4px 0 12px;
      border-right: 1px solid rgba(white, 0.35);
      &:last-child {
        border: none;
      }
    }
  }

  .document-row-container {
    padding: 12px;
    min-height: 40px;
    height: 100%;
    position: relative;
    border-radius: 4px;
    .vertical {
      height: 100%;
      min-height: 40px;
      width: 1px;
      background-color: rgba($color-grey-blue, 0.6);;
    }
    &:nth-child(odd) {
      background-color: white;
    }
    &:nth-child(even) {
      background-color: rgba($color-grey-blue, 0.25);
    }
    .doc-row-text {
      position: relative;
      height: 100%;
      min-height: 100%;
      padding: 0 2px 0 6px;
      border-left: 1px solid rgba($color-grey-blue, 0.6);
      &:first-child {
        border: none;
      }
    }
  }
}