@import 'src/assets/css/variables.scss';

.link-container-span,
.link-container,
.link-container > a,
.link-container > p,
.link-container > span {
    border: none;
    font-family: $font-regular;
    font-size: $font-size-regular;
    color:  $color-blue;  
    cursor: pointer;
    text-decoration: underline $color-blue;
    overflow: hidden;
    text-overflow: ellipsis;
    display:-webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    word-break: break-all;
    &.small {
        font-size: $font-size-small;
        display: inline-block;
    }
    &.big-text {
        font-size: 16px;
    }

    &.details {
        -webkit-line-clamp: 2;
        word-break: normal;
      }

    img {
        width: 16px;
        height: 21px;
        &.big-icon {
            width: 22px;
            height: 22px;
        }
    }

    &.success {
        color: $color-green !important;  
        font-weight: bold; 
        text-decoration: none;
        cursor: default;
        overflow: visible;  /* Ensures that overflow text is not clipped */
        text-overflow: clip;  /* Default value, allows overflow text to show */
        white-space: normal;  /* Overrides any parent setting that might hide overflow */
        display: block;  /* Use standard block layout instead of box */
        max-height: none;  /* Remove any height limit that might hide text */
        word-wrap: break-word;
        word-break: break-all; 
    }
    &.failure {
        color:  $color-red !important;  
        text-decoration: none;
        cursor: default;
    }
}

/* New class for showcasing messages */
.showcase-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .showcase-icon {
    margin-right: 8px;
  }

.icon-link {
    &.dark-mode {
        filter: $filter-blue;
    }
}

.center-text {
    display: flex;
    align-items: center;
}
