@import 'src/assets/css/variables.scss';
@import 'src/components/card/CompanyCard/CompanyCard.scss';

$details-container-height: calc(calc(100vh - calc(95px + 70px)));

.company-details-card-container {
  height: $details-container-height;
  overflow-y: auto;
  // margin-top: 116px;
  .company-details-card {
    position: relative;
    background-color: white;
    box-shadow: -1px 2px 8px 0 rgba(165, 173, 189, 0.4);
    border-radius: 6px;
    border: 0 !important;
    padding: 24px 16px;
    max-width: 100%;
    margin: 40px auto;
    width: 90%;
    inset: 0 !important;
    height: auto;
    p, .p {
      color: $color-black;
    }
  }
}

.company-details-content-container {
  margin: 0 auto;
  .icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 16px;
    width: 16px;
    > img {
      height: 16px;
      width: 16px;
    }
  }
  .company-details-authentication-section {
    max-width: 100%;
  }
}
.company-details-container {
  .company-details-info {
    .company-info {
      margin-top: 8px;
      font-family: $font-regular;
      font-size: $font-size-small;
      color: $color-black;
      line-height: 16px;
      .info-icon {
        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.company-details-content-separator {
  margin: 24px -16px;
  height: 1px;
  width: calc(100% + 45px);
  border-bottom: 1px solid $color-blue-extra-light;
}

.company-details-content {
  width: 100%;
}