@import 'src/assets/css/variables.scss';

$company-card-container: 145px;
$top-container-height: calc(95px);
$top-container-height-sm: calc(190px);

.company-container {
  width: 100%;
  
  &.grid-scroll-container {
    margin: 0;
    // height: calc(100vh - #{$top-container-height-sm});
    // overflow-y: auto;
    // @media (min-width: $tablet-grid) {
    //   height: calc(100vh - #{$top-container-height});
    // }
  }

  .company-list-container {
    height: auto;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($company-card-container, 1fr)); /* Adjust the column width as needed */
    row-gap: 24px;
    column-gap: 12px;
    justify-content: space-between;
  }
  
  .scroll-base-container {
    position: absolute;
    width: 100%;
    z-index: 2;
    height: 2;
    bottom: 0 !important;
    box-shadow: 0 -4px 8px 8px rgba(34, 39, 42, 0.9);
  }
}

.providers-main-page {
  overflow: hidden;
}