@import './variables.scss';

.agree-checkbox {
    cursor: pointer;
    transform: scale(1.3);
    
}

.i-agree-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: '4px';
    height: 'auto';
}

.i-agree-label {
    display: flex;
    align-items: center;
    cursor: 'pointer'; 
    white-space: 'nowrap';
    margin-bottom: '4px' 
}

.i-agree-text {
    padding-right: 4px;
    padding-left: 4px;
    font-weight: bold;
    display: inline-block;
}

.i-agree-link {
    flex-shrink: 0;

}