@import 'src/assets/css/variables.scss';
.input-container {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
}

.input-default-style {
    // width: 250px;
    width: 100%;
    margin: 10px auto 10px auto;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    font-family: 'SourceSansPro-Regular';
    font-size: 15px;
    color: black;
    background-color: #ffffff;
    &.required {
        border: 3px solid $color-blue;
    }
    &.register {
        min-width: 275px;
    }
}
.input-medium-default,
.input-small-default,
.input-add-style {
    width: 100%;
    height: 32px;
    border-radius: 8px;
    box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
    background-color: white;
    padding: 7px 12px 6px;
    color: $color-black;
    font-family: 'SourceSansPro-Light';
    font-size: 14px;
    line-height: 18px;
    border: 0;

    &.filled {
        border: 1px solid $color-blue-light;
    }

    &.password {
        padding-right: 32px;
    }

    &.success {
        border: solid 1px $color-green;
    }

    &.failure {
        border: solid 1px $color-red;
    }

    &.disable {
        opacity: 0.5;
    }

    &:focus {
        font-size: 16px;
        /* Revert to 14px on focus */
    }
}

.input-small-default {
    height: 24px;
}

.input-add-style {
    padding-right: 30px;
}

.input-label {
    font-family: $font-light;
    font-size: 14px;
    margin: 0 0 8px 0;
    padding: 0;
    color: $color-black;
    text-align: left;

    &.regular {
        font-family: $font-regular;
    }

    &.invert {
        color: white;
    }
}

.input-row-container {
    display: flex;
    margin: auto;
    position: relative;
    align-items: center;
    width: 100%;

    .prefix-container {
        height: 32px;
        border-radius: 8px;
        box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
        background-color: white;
        color: $color-black;
        font-family: $font-light;
        font-size: 14px;
        img {
            height: 16px;
            width: 16px;
        }
    }
}

.input-small-container-left {
    margin: 0 8px 0 0 !important;
}
.input-small-container-right {
    margin: 0 0px 0 8px !important;
}

