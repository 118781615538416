@import "src/assets/css/variables.scss";
.card-equipment-main-container {
  position: relative;
  width: 100%;
  height: 88px;
  border-radius: 8px;
  background-color: $color-blue-extra-light;
  margin-bottom: 40px;
  padding: 8px 16px 16px 8px;
  color: $color-black;
  max-width: 19.625rem;
  @media (max-width: $tablet-grid) {
    max-width: 21.25rem;
  }
  &:last-child {
    margin-bottom: -40px;
  }
}

.card-equipment-info-container {
  display: flex;
}
.card-equipment-image-container {
  width: 128px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 0;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(165, 173, 189, 0.7);
  img {
    width: auto;
    max-width: 128px;
    height: 64px;
    display: flex;
    object-fit: contain;
  }
}

.card-equipment-text-container {
  max-width: 200px;
  width: 100%;
  line-height: 1.25;
  text-align: left;
}

.card-text-title {
  font-size: 16px;
  max-height: 40px;
  font-family: "SourceSansPro-Semibold";
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 1.25;
  word-break: break-word;
  hyphens: auto;
}
.card-text-subtitle {
  font-size: 14px;
  font-family: "SourceSansPro-Regular";
  line-height: 1.29;
}

.card-text-dark {
  color: $color-black;
}

.card-equipment-button-container {
  display: flex;
  width: auto;
  height: 60px;
  text-align: center;
  margin: auto 10px auto auto;
  padding-right: 15px;
  cursor: pointer;
}

.card-equipment-not-available {
  background: rgba(234, 237, 243, 0.651);
  position: absolute;
  width: 600px;
  height: 60px;
}
.card-equipment-not-available:hover {
  background: rgba(234, 237, 243, 0.651);
  cursor: initial;
}

.edit-button {
  height: 24px;
  width: 24px;
  background-image: $white-gradient;
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(165, 173, 189, 0.75);
  > img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
