@import 'src/assets/css/variables.scss';
$height-bar: 8px;
.progress-bar-container {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    margin: 0 auto;  
}

.progress-bar {
    width: 100%;
    margin: 0 1px;
    box-shadow: 0 2px 3px 0 rgba(165, 173, 189, 0.65);
    background-image: $blue-gradient;
    height: $height-bar;

    &.start { 
        border-top-left-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
    }
   &.startExtra {
        height: 12px;
        border-top-left-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
    } 
    &.middle {
        border-radius: 0;
        &.half-left {
            width: 50%;
            margin-right: 0;
        }
        &.half-right {
            width: 50%;
            margin-left: 0;
            background-image: linear-gradient(to bottom, white 50%, $white);
        }
    }
    &.middleExtra {
        height: 12px;
        border-radius: 0;
        &.half-left {
            width: 50%;
            margin-right: 0;
        }
        &.half-right {
            width: 50%;
            margin-left: 0;
            background-image: linear-gradient(to bottom, white 50%, $white);
        }
    }
    &.end {
        border-top-right-radius: 7.5px;
        border-bottom-right-radius: 7.5px;
    }
    &.endExtra {
        height: 12px;
        border-top-right-radius: 7.5px;
        border-bottom-right-radius: 7.5px;
    }

    &.start-disable {
        border-top-left-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
        &.half-left {
            width: 10%;
            margin-right: 0;
            background-image: $blue-gradient;
        }
        &.half-right {
            width: 90%;
            margin-left: 0;
            background-image: linear-gradient(to bottom, white 50%, $white);
            box-shadow: 0 1px 3px 0 rgba(165, 173, 189, 0.65);
        }
    }
    &.start-disableExtra {
        height: 12px;
        border-top-left-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
        &.half-left {
            width: 10%;
            margin-right: 0;
            background-image: $blue-gradient;
        }
        &.half-right {
            width: 90%;
            margin-left: 0;
            background-image: linear-gradient(to bottom, white 50%, $white);
            box-shadow: 0 1px 3px 0 rgba(165, 173, 189, 0.65);
        }
    }
    &.middle-disable {
        margin: 0 1px;
        box-shadow: 0 1px 3px 0 rgba(165, 173, 189, 0.65);
        background-image: linear-gradient(to bottom, white 50%, $white);
    }
    &.middle-disableExtra {
        height: 12px;
        margin: 0 1px;
        box-shadow: 0 1px 3px 0 rgba(165, 173, 189, 0.65);
        background-image: linear-gradient(to bottom, white 50%, $white);
    }
    &.end-disable {
        border-top-right-radius: 7.5px;
        border-bottom-right-radius: 7.5px;
        box-shadow: 0 1px 3px 0 rgba(165, 173, 189, 0.65);
        background-image: linear-gradient(to bottom, white 50%, $white);
    }
    &.end-disableExtra{
        height: 12px;
        border-top-right-radius: 7.5px;
        border-bottom-right-radius: 7.5px;
        box-shadow: 0 1px 3px 0 rgba(165, 173, 189, 0.65);
        background-image: linear-gradient(to bottom, white 50%, $white);
    }
    &.completed {
        background-color: $color-grey-dark;
        background-image: none;
    }
}


.progress-bar-info {
    width: 100%;
    height: auto;
    margin: auto;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    &.extra {

        .progress-bar-info-text {
            font-size: 14px;
            line-height: 1.25;
            font-family: 'SourceSansPro-Semibold';
        }
    }

    .progress-bar-info-text {
        padding: 0px;
        margin: auto;
        font-size: 14px;
        line-height: 1.25;
        color: $color-blue;
        font-family: 'SourceSansPro-Semibold';
        &.initial-state {
            color: $color-blue;
            //color: $color-black;
        }
        &.final-state {
            color: $color-black;
            font-family: 'SourceSansPro-Regular';
            //color: $color-grey-dark;
        }
        &.alert {
            color: red;
        }
    }
}

