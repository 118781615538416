@import 'src/assets/css/variables.scss';
.button-menu-container {
    display: flex;
    width: 100%;
    height: 56px;
    max-width: 22.625rem;
    cursor: pointer;
    border-bottom: 1px solid rgba($color-blue-extra-light, 0.16);
    padding: 16px;
    justify-content: flex-start;
    &:hover {
        background-color: $color-indigo;
    }
}

.icon-type-container {
    display: flex;
    width: 24px;
    height: 24px;

    img {
        width: 24px;
        height: 24px;
    }
}


.text-menu-button-container{
    width: auto;
    text-align: left;
    display: flex;
    align-items: center;

    .text-menu-button {
        padding: 0px;
        color: white;
        font-size: 16px;
        line-height: 20px;
        font-family: 'SourceSansPro-Regular';
    }
}


.iconArrowContainer{
    display: flex;
    width: 40px;
    height: 50px;
    text-align: center;
    margin: auto;
}

.whatsapp-button-container {
    position: fixed;
    bottom: 16px;
    right: 8px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    z-index: 1;
    display: flex;
    justify-content: center;

    img {
        width: 50px;
        height: 50px;
    }

    @media (max-width: $tablet-grid) {
        display: none;
    }

    &.isServiceDetails {
        display:none;
        z-index: auto;
        @media (max-width: $tablet-grid) {
            display: unset;
            position: unset;
            bottom: 0px;
            right: 0px;
            img {
                width: 45px;
                height: 45px;
            }
        }
    }
}

.playstore-button-container {
    position: fixed;
    bottom: calc(16px + 50px + 16px);
    right: 8px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    z-index: 1;
    display: flex;
    justify-content: center;

    img {
        width: 50px;
        height: 50px;
    }

    @media (max-width: $tablet-grid) {
        bottom: calc(50% - 50px - 16px);
        right: 4px;
    }
}

.media-button {
    display: flex;
    position: fixed;
    height: 40px;
    width: 56px;
    min-width: 40px;
    right: 0px;
    bottom: 85px;
    background-color: white;
    box-shadow: rgba(165, 173, 189, 0.8) -1px 2px 8px 0px;
    cursor: pointer;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    padding: 8px 4px 8px 13px;

    @media (min-width: $tablet-grid) {
            display: none;
    }
}

.media-button .icon-container {
    width: 24px;
    height: 24px;
    margin: 0 3px 0 0;
    object-fit: contain;
    &.small {
        width: 14px;
        height: 14px;
        margin: 6px 0 0 0;
    }
}