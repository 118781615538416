
@import 'src/assets/css/variables.scss';
.toast-container {
    width: 330px;
    position: fixed;
    bottom: 50px;
}

.sucess{ background-color: $color-blue; }
.error{ background-color: #F06767; }
.alert{ background-color: #FFB002; }

.toast {
    width: 220px;
    height: 40px;
    position: relative;
    margin: auto;
    padding-top: 10px;
    text-align: center;
    border-radius: 70px;
    box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.8);
    animation-name: animaToast;
    animation-duration: 0.7s;
} 

.toast-text {
    margin: auto;
    font-family: 'SourceSansPro-Light';
    font-size: 18px;
    color: #FFFFFF; 
}

@keyframes animaToast {
    from {transform: translateY(30%); opacity:0}
    to {transform: none; opacity:1}
}