@import 'src/assets/css/variables.scss';
$color-blue-light: #b7cde3;
.select-list-container{
  text-align: left;
  justify-content: start;

  .select-list-row-container {
    display: flex;
    margin: auto;
    position: relative;
    align-items: center;

    .select-list {
      width: 100%;
      margin: 0 auto auto;
      padding-left: 0px;
      padding-right: 0px;
      border-radius: 8px;
      border-width: 0px;
      box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
      background-color: white;
      border-radius: 8px;
    }
  }
}

/* Assuming 'custom-dropdown-icon.png' is the path to your custom image */
.custom-dropdown-indicator {
  background-image: url('../../assets/images/svgs/icons/search.svg');
  background-repeat: no-repeat;
  background-size: 16px 16px; /* Adjust the size as needed */
  background-position: center; /* Center the image horizontally and vertically */
}

/* Apply the custom styles to the dropdown indicator */
.react-select__dropdown-indicator {
  /* Other styles you want to keep (e.g., color, border) */
  color: transparent; /* Hide the default indicator */
  padding: 0;
}

/* Optionally, hide the default dropdown icon using visibility */
.react-select__dropdown-indicator:after {
  content: none;
  visibility: hidden;
}