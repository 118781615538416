@import "src/assets/css/variables.scss";

$service-card-x: 149px;
$service-card-y: 96px;
$service-card-container: 652px;
$step-summary-container: 652px;

$action-field-container-small: 340px;
$action-field-container-large: 427px;
$action-field-container-xl: 652px;
$slot-time-card: 73px;

.schedule-page-files-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 652px;
  width: 100%;
  min-width: 300px;
}

.schedule-page-container {
  text-align: center;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 72px);

  @media (max-width: $tablet-grid) {
    overflow-y: hidden;
    max-height: 100%;
  }

  .top-information-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .schedule-service-description-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    max-width: $action-field-container-large;
    width: 100%;
    height: 24px;
    padding: 2px 12px;
    border-radius: 8px;
    background-color: rgba($color-blue-light, 0.5);
    color: $color-black;

   // @media (max-width: $tablet-grid) {
   //   max-width: $action-field-container-small;
   // }
    .service-description-text {
      font-family: $font-regular;
      font-size: $font-size-regular;
      line-height: 16px;
      white-space: nowrap;
      margin-right: 8px;
    }

    .service-param-info {
      font-family: $font-semibold;
      font-size: $font-size-medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .steps-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // max-width: $action-field-container;
    width: 100%;
    height: 24px;

    .step {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;
      border: solid 2px $color-blue-dark;
      background-color: white;
      border-radius: 50%;
      font-family: $font-semibold;
      font-size: $font-size-medium;
      color: $color-black;
      opacity: 1;

      &.active {
        background-color: $color-blue-dark;
        color: white;
      }

      &.disabled {
        opacity: 0.15;
      }
    }
  }

  .step-action-container {
    width: 100%;

    .step-description-container {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $color-black;
      font-size: $font-size-medium;

      .step-label {
        font-family: $font-semibold;
      }

      .step-description {
        font-family: $font-light;
      }
    }
  }

  .step-action-field-content {
    max-width: $action-field-container-large;
    margin: auto;

    @media (max-width: $tablet-grid) {
      max-width: $action-field-container-small;
    }

    .slots-grid-container  {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax($slot-time-card, 1fr));
      /* Adjust the column width as needed */
      column-gap: 8px;
      row-gap: 8px;
      /* Adjust the gap between grid items */
      justify-content: space-between;

      .slot-card {
        width: $slot-time-card;
        height: 24px;
        background-color: $color-blue-extra-light;
        color: $color-black;
        font-size: 1rem;
        font-family: $font-regular;
        text-align: center;

        &.active {
          font-family: $font-semibold;
          font-size: 1rem;
          color: $color-blue;
          box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
        }

        &:hover, &:active, &:focus {
          box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
        }
      }
    }
  }

  .step-action-content {
    max-width: $service-card-container;
    width: 100%;
    min-width: 300px;
    display: inline-flex;

    &.grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax($service-card-x, 1fr));
      /* Adjust the column width as needed */
      column-gap: 16px;
      row-gap: 16px;
      /* Adjust the gap between grid items */
      justify-content: space-between;
    }

    .step-summary-container {
      width: 100%;
      max-width: $step-summary-container;


    }

    .schedule-service-card {
      width: $service-card-x;
      height: 100%;
      min-height: $service-card-y;
      margin: auto;
      background-color: $color-blue-extra-light;
      border-radius: 13px;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .schedule-service-name {
        text-align: center;
        color: $color-black;
        font-family: $font-regular;
        line-height: 18px;
        font-size: $font-size-regular;
      }

      &:hover,
      &:active,
      &.active {
        box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);

      }
      &.active {
        .schedule-service-name {
          color: $color-blue;
        }
      }
      &.disabled {        
        .schedule-service-name {
          text-align: center;
          color: $color-grey-dark;
          font-family: $font-regular;
          line-height: 18px;
          font-size: $font-size-regular; 
        }
      }
    }
   // @media (max-width: $tablet-grid) {
   //   max-width: $action-field-container-small;
   // }
  }

  .step-action-observations-container {
    max-width: $action-field-container-xl;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;

    .observations-label {
      text-align: start;
    }
    .observations-symptoms-label {
      text-align: start;
    }
    @media (max-width: $tablet-grid) {
      max-width: $action-field-container-small;
    }
  }

  .cta-buttons-container {
    width: 100%;
    max-width: $action-field-container-large;
    display: inline-flex;
    justify-content: center;
    margin: auto;
    .cta-button-secondary,
    .cta-button-primary {
      max-width: 100%;
    }
    @media (max-width: $tablet-grid) {
      max-width: $action-field-container-small;
    }
  }

  .symptom-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .bullet-point {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-indigo;
    }

    .symptom-name {
      font-size: $font-size-medium;
      font-family: $font-light;
    }
  }
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}