@import 'src/assets/css/variables.scss';
@import 'src/components/card/CompanyCard/CompanyCard.scss';

$details-container-height: calc(calc(100vh - calc(95px + 70px)));

.company-details-card-container {
  height: $details-container-height;
  overflow-y: auto;
  .company-details-card {
    position: relative;
    background-color: white;
    box-shadow: -1px 2px 8px 0 rgba(165, 173, 189, 0.4);
    border-radius: 6px;
    border: 0 !important;
    padding: 24px 16px;
    max-width: 100%;
    margin: 40px auto;
    width: 90%;
    inset: 0 !important;
    height: auto;
    p, .p {
      color: $color-black;
    }
  }
}

.expanded-company-container {
  .company-details-card-container {
    height: auto;
    margin: 0 auto;
    padding: 16px;
    .company-details-card {
      width: 100%;
      margin: 0 auto;
      border-radius: 8px;
      padding: 8px 4px;
      background-color: $color-indigo-dark;
      box-shadow: none;
      .card-button-container {
        position: absolute;
        top: 10px;
        right: 16px;
        height: 100%;
        width: 16px;
        z-index: 2;
        padding: 30px 8px 50px 8px;
        > img {
          height: 16px;
          width: 16px;
        }
      }
      &.current {
        box-shadow: 0 2px 7px 0 #000;
        background-color: $color-indigo;
      }
    }

    .company-details-container {
      width: 100%;
      padding: 8px 24px 8px 12px;
    }
  }

}



.company-details-content-container {
  margin: 0 auto;
  .icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 16px;
    width: 16px;
    > img {
      height: 16px;
      width: 16px;
    }
  }
  .company-details-authentication-section {
    max-width: 100%;
  }
}
.company-details-container {
  .company-details-info {
    .company-info {
      margin-top: 8px;
      font-family: $font-regular;
      font-size: $font-size-small;
      color: $color-black;
      line-height: 16px;
      .info-icon {
        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.company-details-content-separator {
  margin: 24px -16px;
  height: 1px;
  width: calc(100% + 32px);
  border-bottom: 1px solid $color-blue-extra-light;
}

.company-details-content {
  width: 100%;
}