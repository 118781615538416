@import 'src/assets/css/variables.scss';

$category-card-x: 149px;
$category-card-y: 96px;
.category-card {
  width: $category-card-x;
  height: 100%;
  min-height: $category-card-y;
  margin: auto;
  background-color: $color-indigo-dark;
  border-radius: 13px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .category-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 32px;
    > img {
      height: 32px;
      width: 32px;
      filter: $filter-white;
    }
  }
  .category-name {
    text-align: center;
    color: white;
    font-family: $font-regular;
    line-height: 18px;
    font-size: $font-size-regular;
  }

  &:hover, &:active {
    @media (min-width: $desktop-grid) {
      box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.5);
      .category-icon {
        > img {
          filter: $filter-blue;
        }
      }
      .category-name {
        color: $color-blue;
      }
    }
  }
}