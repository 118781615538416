/*
* SourceSansPro
*/
@font-face {
    font-family: 'SourceSansPro-Black';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro Black'),
      local('SourceSansPro-Black'),
      url(./SourceSansPro/SourceSansPro-Black.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-BlackIt';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro BlackIt'),
      local('SourceSansPro-BlackIt'),
      url(./SourceSansPro/SourceSansPro-BlackIt.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-Bold';
    font-style: normal;
    font-weight: 700;
    src:
      local('SourceSansPro Bold'),
      local('SourceSansPro-Bold'),
      url(./SourceSansPro/SourceSansPro-Bold.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-BoldIt';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro BoldIt'),
      local('SourceSansPro-BoldIt'),
      url(./SourceSansPro/SourceSansPro-BoldIt.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-ExtraLight';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro ExtraLight'),
      local('SourceSansPro-ExtraLight'),
      url(./SourceSansPro/SourceSansPro-ExtraLight.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-ExtraLightIt';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro ExtraLightIt'),
      local('SourceSansPro-ExtraLightIt'),
      url(./SourceSansPro/SourceSansPro-ExtraLightIt.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-It';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro It'),
      local('SourceSansPro-It'),
      url(./SourceSansPro/SourceSansPro-It.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-Light';
    font-style: normal;
    font-weight: 300;
    src:
      local('SourceSansPro Light'),
      local('SourceSansPro-Light'),
      url(./SourceSansPro/SourceSansPro-Light.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-LightIt';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro LightIt'),
      local('SourceSansPro-LightIt'),
      url(./SourceSansPro/SourceSansPro-LightIt.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-Regular';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro Regular'),
      local('SourceSansPro-Regular'),
      url(./SourceSansPro/SourceSansPro-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-Semibold';
    font-style: normal;
    font-weight: 600;
    src:
      local('SourceSansPro Semibold'),
      local('SourceSansPro-Semibold'),
      url(./SourceSansPro/SourceSansPro-Semibold.otf) format('opentype');
}
@font-face {
    font-family: 'SourceSansPro-SemiboldIt';
    font-style: normal;
    font-weight: normal;
    src:
      local('SourceSansPro SemiboldIt'),
      local('SourceSansPro-SemiboldIt'),
      url(./SourceSansPro/SourceSansPro-SemiboldIt.otf) format('opentype');
}

