@import 'src/assets/css/variables.scss';
.header {
  display: flex;
  margin: auto;
  overflow: hidden;
  height: 72px;

  .logo-container {
    max-width: 55%;
    height: 56px;
    .company-logo {
      text-align: left;
      img {
        width: auto;
        height: 56px;
      }
      > a {
        text-decoration: none;
        color: $color-black;;
      }
    }
    
  }
  .app-logo-container {
    height: 64px;
    display: flex;
    text-align: right;
  }

  @media (max-width: $tablet-grid) {
    max-height: 56px;
    .app-logo-container {
      height: 100%;
      max-width: 150px;
    }
    .logo-container {
      max-height: 48px;
      height: 100%;
      width: auto;
      max-width: 50%;
      .company-logo {
        img {
          max-height: 48px;
          height: 100%;
        }
        .company-name {
          font-size: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
    }
  }

}
.company-name {
  font-family: SourceSansPro-Bold;
  font-size: 28px;
  color: $color-black;
  line-height: 1.14;
  overflow: hidden;
  text-overflow: ellipsis;
  display:-webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.app-logo-text {
  font-family: SourceSansPro-Regular;
  font-size: 13px;
  color: $color-black;
  text-decoration: none;
  &:hover {
    color: $color-black;
  }
  .link-container {
    font-family: SourceSansPro-SemiBold;
    font-size: 14px;
    text-decoration: underline;
    @media (max-width: $tablet-grid) {
      font-size: 13px;
    }
  }
  .product-name {
    font-family: SourceSansPro-Bold;
    text-decoration: underline;
    font-size: 14px;
    .special-character {
      color: $color-blue;
    }
  }
}
.leftLogo > a {
  font-family: 'SourceSansPro-Regular';
  margin: 10px auto auto 10px;
  text-align: center;
  font-size: 25px;
  color: $color-black;
  text-decoration: none; 
}

@media (max-width: $tablet-grid) {
  .leftLogo > a {
    font-family: 'SourceSansPro-Regular';
    margin: 10px auto auto 10px;
    text-align: center;
    font-size: 0px;
    color: $color-black;   
  }
  .company-name {
    font-size: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display:-webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}
