@import 'src/assets/css/variables.scss';


.obs-history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  height: fit-content;
  max-height: calc(100vh - 130px); // to avoid send button coliding with gallery button
  margin: 1px auto auto;
  border-radius: 4px;
  list-style-type: none;
  background-color: transparent;
  position: relative;
  padding: 0 8px;
  border-radius: 8px;

  &.grid-closed {
    padding: 0 22px 0 8px;
    @media (max-width: $tablet-grid) {
      padding: 0 16px 0 16px;
    }
  }
  @media (max-height: 660px) {
    &.mobileKeyboardVisible {
      max-height: unset;
    }
  }

  &.shining {
      outline: 3px solid $color-orange;
      width: calc(100% - 6px);
      margin: auto;
      transition: outline 0.3s ease, width 0.3s ease 
  }
  & {
      outline: 0 solid transparent;
      width: 100%;
      transition: outline 0.3s ease, width 0.3s ease 
  }  

  .title {
      font-family: $font-light;
      font-size: $font-size-regular;
      color: $color-black;
      text-align: left;
      margin-bottom: 8px;
  }

  .history {
      width: 100%;
      max-height: 276px;
      overflow-y: auto;
      background-color: $color-blue-extra-light;
      border-radius: 8px;
      position: relative;
      cursor: pointer;

      .box-shadow {
        width: 100%;
        position: sticky;
        top: -1px;
        box-shadow: $box-shadow;
        width: 100%;
        &.bottom {
          top: unset;
          bottom: 2px;
        }
      }
      
      @media (max-width: $tablet-grid) {
        background-color: $color-white;
      }
      
      &.open {
          max-height: calc(100vh - 200px);
          cursor: default;
          @media (min-width: 768px) { 
            max-height: 480px; 
          }
      }
      @media (max-height: 660px) {
        &.open.mobileKeyboardVisible {
          max-height: calc(100vh - 150px);
        }
      }
  }

  .chat-comment {
    width: fit-content;
    max-width: calc(100% - 32px);
    box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
    border-radius: 13px;
    overflow: hidden;
    margin-bottom: 8px;
    margin-right: 32px;
  
    &.client {
        margin-right: 0;
        margin-left: auto;
        background-color: $color-blue-extra-light;
    }

    &.fullwidth{
      max-width: 100%;
      width: 100%;
    }

    .comment-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        padding: 4px 8px;
        color: $color-black;
        font-family: $font-regular;
        font-size: $font-size-small;
        background-color: $color-blue-light;
        &.client {
          background-color: $color-yellow-light;
        }

        .header-sender {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .header-date {
          min-width: 90px;
          text-align: right;
        }
    }
    .comment-body {
        font-family: $font-regular;
        font-size: $font-size-regular;
        width: 100%;
        background-color: $color-white;
        padding: 8px;
        text-align: start;
        > span {
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          word-wrap: break-word;
          lang: "pt-PT";
        }
    }
  }


  .obs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    textarea {
      resize: none;

      &:focus,
      &:focus-active,
      &:active {
        border: 1px solid $color-orange !important;
      }
    }
    &.grid-closed {
      padding: 0 2px 0 16px;
      @media (max-width: $tablet-grid) {
        padding: 0 2px 8px 0;
      }
    }
        .obs-container-text-area {
          position: relative;
          width: auto;
          border-radius: 8px;
          border-width: 0px;
          box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
          background-color: white;
          padding: 6px 48px 6px 12px;
          font-family: "SourceSansPro-Regular";
          font-size: 14px;
          color: $color-black;
          border: 1px solid $color-yellow !important;
          height: 54px;

          &.chat {
            height: 54px;
          }
    
          &.grid-closed {
            height: 35px;
            border: unset !important;
            font-size: 18px;
            margin: 2px 0 0 0px;
          }
    
          &.small {
            height: 32px;
            resize: none;
          }
    
          &.medium {
            width: 100%;
            height: auto;
            min-height: 100px;
    
            button: {
              right: 16px;
            }
          }
    
          &:focus {
            font-size: 16px;
            /* Revert to 14px on focus */
          }
        }
    
        .inside-textarea-button-container {
          position: absolute;
          transform: translateY(-50%);
          right: 12px;
          width: 24px;
          height: 24px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          cursor: pointer;
          padding: 4px;
          border-radius: 50%;
          box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.7);
          bottom: 24px;
          top: 11px;
          bottom: 0;
          -webkit-transform: translateY(0);
          transform: translateY(0);
    
          &.grid-closed {
            top: 4px;
          }

          &.loading {
            top: 23px;
            box-shadow: none;
            background-color: unset;
            bottom: 0;
          }
        
          @media (max-width: $tablet-grid) {
            right: 12px;
          }
        }
      }
}

