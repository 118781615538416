@import 'src/assets/css/variables.scss';
.select-list-container{
  text-align: center;
  justify-content: center;

  .select-list-row-container {
    display: flex;
    margin: auto;
    position: relative;
    align-items: center;

    .select-list {
      width: 100%;
      margin: 0 auto auto;
      padding-left: 0px;
      padding-right: 0px;
      border-radius: 8px;
      // border: 1px solid #FFFFFF;
      box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
      background-color: #ffffff;
      &.required {
        border: 1px solid $color-blue-light;
      }
    }
  }
}
.onSymptomListContainer{
  width: 100%;
  max-width: 308px;
  font-size: 14px;
  border-radius: 8px;
  border-width: 0px;
  padding: 0px 0px 6px 0px;
  .select-list-row-container {
    display: flex;
    margin: auto;
    position: relative;
    align-items: center;

    .select-list {
      width: 100%;
      margin: 0 auto auto;
      padding-left: 0px;
      padding-right: 0px;
      border-radius: 8px;
      box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
      background-color: #ffffff;
      &.required {
        border: 1px solid $color-blue-light;
      }
      bottom: 100%; 
    }
  }
}