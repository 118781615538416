@import "src/assets/css/variables.scss";
// Required

.container-kss {
  @media (min-width: calc(#{$breakpoint-1-view})) {
    max-width: $breakpoint-1-width;
    max-width: 100%;
  }
  @media (min-width: calc(#{$breakpoint-2-view})) {
    max-width: $breakpoint-2-width;
    max-width: 100%;
  }
  @media (min-width: calc(#{$breakpoint-3-view})) {
    max-width: $breakpoint-3-width;
    max-width: 100%;
  }
  @media (min-width: calc(#{$breakpoint-4-view})) {
    max-width: $breakpoint-4-width;
    max-width: 100%;
  }
  @media (min-width: calc(#{$breakpoint-5-view})) {
    max-width: $breakpoint-5-width;
    max-width: 100%;
  }
  @media (min-width: calc(#{$breakpoint-6-view})) {
    max-width: $breakpoint-6-width;
    max-width: 100%;
  }
}

.main-logo {
  font-family: "SourceSansPro-Regular";
  font-size: 25px;
  color: $color-black;
  margin: 10px 0 0 10px;
  text-decoration: none;
  position: absolute;
}
.main-container {
  display: flex;
  min-width: 300px;
  height: calc(100vh - 64px);
  text-align: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;

  &.overflow-y {
    overflow-y: auto;
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  &.overflow-hidden-mobile {
    @media (max-width: $tablet-grid) {
      overflow: hidden;
    }
  }

  .list-view-container.overflow-hidden-mobile.overflow-hidden-mobile-forced {
    overflow: hidden;
  }
}

.main-details-container {
  display: flex;
  min-width: 300px;
  height: calc(100vh - 64px);
  text-align: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;

  &.overflow-y {
    overflow-y: auto;
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  &.overflow-hidden-mobile {
    @media (max-width: $tablet-grid) {
      overflow: hidden;
    }
  }
}

.main-container h1 {
  font-family: "SourceSansPro-Regular";
  font-size: 45px;
  color: $color-black;

  @media (max-width: $tablet-grid) {
    font-size: 30px;
    margin-top: 5vh;
  }
}

.inline-column {
  position: relative;
  display: inline-block;
  justify-content: center;
}

.simpleArea {
  width: auto;
  height: auto;
  padding: 20px;
  text-align: left;
}

.block-list {
  margin: 20px auto;
  padding-bottom: 30px;
  display: block;
  overflow: auto;
  max-width: 480px;
  width: 95%;
  display: flex;
}

.list-view-container {
  @media (min-width: $tablet-grid) {
    display: flex;
    position: relative;
    margin: auto;
    padding: 0 24px 32px 24px;
    text-align: center;
    display: inline;
    justify-content: center;
    align-items: center;
    overflow: auto;
    width: 100%;
    height: 100%;
  }
}

.center-container {
  width: 85%;
  height: auto;
  text-align: center;
  margin: 40px auto auto;
  justify-content: center;
}

.center-container-left {
  width: 50%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  padding-right: 12px;
}

.center-container-right {
  width: 50%;
  height: auto;
  text-align: center;
  margin: 0 auto 50px auto;
  justify-content: center;
  padding-left: 12px;
}

.form-flex-container {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.d-inline-flex {
  display: inline-flex;
}


.form-container-inline {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  #checkTerms {
    margin-right: 5px;
  }
}


.cover-Info-container-text1 {
  width: 92%;
  color: $color-black;
  text-align: left;
  padding-top: 8px;
  padding-left: 20px;
  font-size: 1.8rem;
  line-height: 100%;
  font-weight: bold;
  font-family: "SourceSansPro-Regular";
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spec-row {
  min-height: 40px;
  border-radius: 4px;
  margin-top: 1px;
  &:nth-child(odd) {
    background-color: $color-blue-extra-light;
  }
  &:nth-child(even) {
      background-color: rgba($color-grey-blue, 0.25);
  }
  .spec-text {
    font-size: 14px;
    border-left: 1px solid rgba($color-grey-blue, 0.6);
    &:first-child {
      border: none;
    }
  }
}

.information-area-container {
  width: 95%;
  max-width: 590px;
  margin: 0 auto 30px auto;
  height: auto;
  display: block;
  position: relative;

  .information-area-header {
    color: $color-black;
    text-align: center;
    margin: 10px auto;
    font-size: 30px;
    font-family: "SourceSansPro-Regular";
    font-weight: 700;
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  .information-area-label {
    width: 50%;
    min-width: 150px;
    text-align: right;
    color: $color-black;
    font-size: 16px;
    font-family: "SourceSansPro-Regular";
    font-weight: 700;
  }
  .information-area-text {
    color: $color-black;
    font-size: 16px;
    text-align: left;
    padding-left: 15px;
    font-family: "SourceSansPro-Light";
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 50%;
  }
}
.tasks-empty-list {
  color: $color-black;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  font-family: "SourceSansPro-Light";
}

.menu-state-message {
  color: #ffffff;
  margin: 0px auto;
  font-size: 16px;
  font-family: "SourceSansPro-Regular";
  text-align: center;
  box-shadow: -12px -4px 8px 8px rgba(34, 39, 42, 0.9);
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.text-area-container-big {
  width: 100%;
  height: auto;
  min-height: 100px;
  border-radius: 8px;
  border-width: 0px;
  box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
  background-color: white;
  padding: 6px 5px 6px 12px;
  font-family: "SourceSansPro-Regular";
  font-size: 14px;
  line-height: 18px;
  color: $color-black;
  &:focus {
    font-size: 16px; /* Revert to 14px on focus */
  }
}

.add-image-button-container {
  width: 32px;
  height: auto;
  margin: auto;
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
  .custom-icon-container {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
    box-shadow: 1px 0 6px 0 rgba(165, 173, 189, 0.7);
  }
  .icon-button {
    width: 24px;
    height: 24px;
    margin: 4px auto auto auto;
    cursor: pointer;
  }
}

input[type="file"] {
  display: none;
}

.detail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: 0;
  border-radius: 25px;
  background-color: $color-blue;
  box-shadow: 0px 2px 14px rgba(0, 169, 253, 0.5);
}

.rating-container {
  display: flex;
  margin: auto;
}

.rate {
  height: 100%;
  margin: 10px auto 20px;
  display: flex;
  align-items: center;
}
.rate:not(:checked) > input {
  display: none;
}
.rate:not(:checked) > label {
  float: right;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 70px;
  line-height: 100%;
  color: #ccc;
  @media (max-width: 768px) {
    font-size: 45px;
  }
}

.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: $color-blue;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: rgb(26, 143, 201);
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: rgb(0, 117, 175);
}

.empty-services-container {
  width: 100%;
  font-size: 16px;
  padding-top: 56px;
  padding-bottom: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.progress-bar-section {
  display: flex;
  align-items: center;
}

.hidden {
  display: none !important;
  height: 0;
}

.ui {
  &.input > input {
    width: 100%;
    margin: 10px auto 10px auto;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    font-family: "SourceSansPro-Regular";
    font-size: 15px;
    color: black;
    background-color: #ffffff;
    &:hover,
    &:active,
    &:focus {
      outline: unset;
    }
  }
}

textarea {
  &:focus,
  &:focus-active,
  &:active {
    border: 1px solid $color-orange !important;
  }
}

.welcome-message-container {
  width: 100%;
  margin: 50px auto 0;
  padding: 20px 15px 20px 15px;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  .ask-container {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    .ask {
      max-width: 800px;
      @media (max-width: $phone-grid) {
        p {
          font-size: 14px;
        }
      }
      h3 {
        font-size: 18px;
        @media (max-width: $phone-grid) {
          font-size: 16px;
        }
      }
      .btn-container {
        margin: 0;
        @media (max-width: $desktop-grid) {
          margin: 0 auto;
        }
      }
    }
  }
  @media (max-width: $desktop-grid) {
    padding: 20px 15%;
    text-align: center;
  }
  @media (max-width: $phone-grid) {
    text-align: center;
    padding: 20px 5%;
  }
}

.no-services-message-container {
  font-size: 20px;
  line-height: 25px;
}

.ask-container {
  margin-top: 60px;
  width: 100%;
  .ask {
    font-size: 18px;
    line-height: 30px;
    &.inverted {
      color: white;
      h3 {
        font-size: 18px;
        color: white;
        font-family: $font-semibold;
      }
    }
    p {
      font-family: $font-regular;
    }

    .btn-container {
      display: flex;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      justify-content: space-evenly;
      flex-direction: row;
      .button-main-container {
        width: 175px;
      }
      @media (max-width: $phone-grid) {
        flex-direction: column;
        .button-main-container {
          width: 100%;
        }
      }
    }
  }
}

button {
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.disabled {
  pointer-events: none !important;
}

textarea,
input {
  &:focus,
  &:active {
    outline: none;
    &.filled {
      outline: none;
      border: none;
    }
  }

  &.filled {
    outline: none;
    border: solid $color-blue-light 1px;
  }

  &::placeholder {
    color: $color-grey-blue;
  }
}

.message-info {
  font-size: 18px;
  line-height: auto;
  @media (max-width: $phone-grid) {
    font-size: 15px;
  }
}

.grey-box-container {
  margin: 0 auto;
  max-width: 427px;
  height: fit-content;
  padding: 8px 40px 24px 40px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
  border-radius: 13px;
  font-family: SourceSansPro-Light;
  font-size: 14px;
  color: $color-black;
  &.big {
    // max-width: 85%;
    min-height: 286px;
    max-width: 286px;
  }
  &.verybig {
    // max-width: 85%;
    min-height: 186px;
    padding: 8px 40px 24px;
  }
  .message-container {
    font-family: SourceSansPro-Light;
    font-size: 16px;
  }
  .check-icon-container {
    width: 72px;
    height: 72px;
    text-align: center;
    border: 2px solid $color-green;
    border-radius: 50%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
    img {
      width: 48px;
      height: 48px;
    }
  }
}

.company-logo {
  height: 56px;
}

.kmclient-logo {
  width: 64px;
  height: 64px;
  margin: 2px 65px 16px 65px;
  object-fit: contain;
}

.modal-header {
  border-bottom: none;
  border: none;
  height: 0;
  padding: 0;
}

.border-mask {
  height: 85px;
  padding: 32px 25px 33px 24px;
  border: dashed 1px $color-blue-light;
}

@media (max-width: $desktop-grid) {

  .main-details-container {
    display: block;
    width: 100%;
    min-width: 0;
    text-align: center;
    justify-content: center;
    padding: 0 16px;
    height: calc(100vh - 64px);
    overflow-y: auto;
  }
  
  .center-container-left {
    width: 100%;
    height: auto;
    min-width: 0;
    text-align: center;
    margin: auto 0;
    justify-content: center;
    padding-right: 0;

    .information-area-container {
      .information-area-header {
        font-size: 22px;
      }
    }
  }

  .center-container-right {
    width: 100%;
    height: auto;
    text-align: center;
    margin: auto;
    justify-content: center;
    min-width: 0;
    padding-bottom: 32px;
    padding-left: 0;
  }
}

@media (max-width: $tablet-grid) {
  .main-container {
    display: block;
    height: auto;
    width: 100vw;
    min-width: 0;
    text-align: center;
    justify-content: center;
    padding: 0 16px;

    .cover-Info-container-text1 {
      font-size: 1.6rem;
      line-height: 100%;
    }
    .converInfoContainerText2 {
      font-size: 20px;
      line-height: 100%;
    }
  }

  .main-details-container {
    display: block;
    height: auto;
    width: 100%;
    min-width: 0;
    text-align: center;
    justify-content: center;
    padding: 0 16px;
  }

  .cover-image-container {
    width: 100%;
    height: auto;
    margin: auto;
    border-radius: 0px;
    background-color: dimgrey;
  }

  .grey-box-container {
    width: 340px;
    height: auto;
    padding: 8px 40px 24px 40px;
    border-radius: 13px;
    box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
    background-color: $color-blue-extra-light;
    &.big {
      max-width: 100%;
    }
    &.verybig {
      // max-width: 85%;
      min-height: 344px;
      padding: 8px 40px 24px 40px;
    }
  }
  .company-logo {
    height: 48px;
  }

  .swiper-services-container {
    .swiper-tabs-container {
      background-color: white;
      z-index: 2;
      .swiper-tab {
        font-family: $font-semibold;
        font-size: 15px;
        line-height: 18px;
        height: 40px;
        color: $color-black;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        // text-align: left;
        // padding-left: 0;

        .swiper-title-counter {
          width: auto;//0.875rem;
          height: 1rem;
          margin: 0.25rem 0 0 0.25rem;
          font-family: $font-regular;
          font-size: 0.75rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $color-black;
        }
        .swiper-title-counter-alert {
          padding: 0 0 0 6px; //0.063rem;
          margin: 12px 0px auto 4px;
          width: 0.438rem;
          height: 0.406rem;
          border-radius: 100%;
          background-color: $color-orange;
        }
      }
      &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100%);
        padding-left: 16px;
        padding-right: 16px;
        box-shadow: -3px 0 16px 0 rgba(165, 173, 189, 1);
      }
    }
  }
}

.notifications-container-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;

  .notifications-container-group {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .notifications-container-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      display: flex;
      flex: 0 0 auto;
      height: 48px;
      max-height: 48px;
      min-height: 48px;
      background-color: $color-white;
      z-index: 1;

      .notifications-container-button {
        flex: 0 0 auto;
        margin: auto;
      }
      .notifications-container-title {
        flex: 1 1 auto;
        font-family: $font-semibold;
        font-size: 16px;
        line-height: 18px;
        color: $color-black;
        margin: auto 0 auto 8px;
        text-align: left;
      }
    }

    .notifications-container-list {
      position: absolute;
      top: 48px;
      left: 0;
      width: 100vw;
      flex: 1 1 auto;
      height: calc(100% - 108px);
      height: auto;

      .notifications-container-list-item {
        position: relative;
        min-height: 56px;
        font-family: $font-semibold;
        font-size: 12px;
        line-height: 18px;
        color: $color-black;
        background-color: $color-white;
        margin: 4px 16px;
        padding: 4px 0px;
        text-align: left;
        cursor: pointer;

        &.notifications-container-list-item-unread {
          background-color: $color-blue-extra-light;
        }

        .notifications-container-list-item-title {
          font-family: $font-semibold;
          font-size: 12px;
          line-height: 18px;
          color: $color-black;
        }

        .notifications-container-list-item-text {
          font-family: $font-regular;
          font-size: 12px;
          line-height: 14px;
          color: $color-grey-dark;
        }
      }

      .notifications-container-list-item::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-grey-extra-light;
        margin-bottom: -2px;
        z-index: 1;
      }
    }

  }
}
