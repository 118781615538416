@import "src/assets/css/variables.scss";
$date-picker-width: 652px;
$date-picker-width-mobile: 340px;

.date-picker-container {
  width: 100%;
  max-width: $date-picker-width;

  @media (max-width: $tablet-grid) {
    max-width: $date-picker-width-mobile;
  }

  .react-datepicker__month-container {
    @media (max-width: $tablet-grid) {
      &:nth-of-type(3) {
        display: none !important;
      }
    }
  }
}

.react-datepicker {
  font-family: $font-semibold;
  font-size: 16px;
  padding: 4px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 $color-grey-blue;
  display: flex;
  position: relative;
  justify-content: space-around;

}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: white;
  margin: 0;
  border-bottom: none;
  padding-bottom: 0;
  font-size: 14px;

  select {
    border: none;
    background-color: white;

    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .calendar-arrow-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;

    img {
      filter: $filter-black;
      height: 16px;
    }
  }

  .calendar-arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;

    img {
      filter: $filter-black;
      height: 16px;
    }
  }
  .react-datepicker__navigation {
    &.react-datepicker__navigation--previous {
      img {
        filter: $filter-black;
        height: 16px;
      }
    }
    &.react-datepicker__navigation--next {
      img {
        filter: $filter-black;
        height: 16px;
      }
    }
  }
}

.react-datepicker__day {
  font-family: $font-regular;
  color: $color-black;
  font-size: $font-size-medium;
  width: 40px;
  line-height: 40px;
  margin: 4px 2px;

  &-names {
    line-height: 44px;

    .react-datepicker__day-name {
      font-family: $font-regular;
      color: $color-grey-blue;
      width: 40px;
      margin: 2px;
      line-height: 44px;
    }
  }

  &--keyboard-selected {
    background-color: inherit;
  }

  &--today {
    font-family: $font-light;
    background-color: $color-yellow-light;
    border-radius: 22px;
    margin: 6px 5px;
    line-height: 30px;
    width: 32px;
  }

  &--outside-month {
    color: $color-grey-blue;
  }

  &--selected {
    font-family: $font-semibold;
    background-color: $color-blue;
    border-radius: 22px;
    box-shadow: 1px 2px 12px 0 rgba(0, 169, 253, 0.5);
    color: white;

    &:hover {
      background-color: $color-blue;
    }

    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
  }

  &--disabled {
    color: rgba($color-black, 0.15);
  }


  &:hover {
    border-radius: 22px;
    background-color: $color-blue-light;
    color: white;
  }
}