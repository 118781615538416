@import "../../assets/css/variables.scss";

.modalBanner-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.modalBanner-modal {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #ccc;
  padding: 40px 0 0 0 !important;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  width: 100% !important;
  max-width: 100%  !important;
  margin: auto 0px 0px 0px !important;
  text-align: center;
  overflow: hidden !important;
  max-height: calc(100vh - 20px) !important; // Adjusted maxHeight to leave 20px at the top
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out; // Smooth transition for height and padding
}

/* Increase specificity to ensure styles are not overridden*/ 
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open.modalBanner-modal {
  padding: 20px 1px 16px 0 !important;
  border: none !important;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out; // Smooth transition for height and padding
}

.modalBanner-content {
  font-family: $font-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: white;
  padding: 2px;
  width: 100%;

  &.topShadow {
    box-shadow: 0px -7px 8px 0px #d0d0d0; // Added shadow only at the top
  }
}

.modalBanner-buttons {
  display: flex;
  flex-direction: row; // Changed to row to arrange buttons side by side
  gap: 10px;
  min-width: 280px;
}

.modalBanner-switches {
  display: flex;
  flex-direction: row; // Changed to row to arrange buttons side by side
  min-width: 180px;
  flex-wrap: wrap;
  justify-content: center;
}

.modalBanner-detailsContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  height: 6px !important;
  transition: height 0.5s ease;
  overflow-y: auto;
  background: white;
  margin-bottom: 20px;
}

.modalBanner-closeButton {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
}

// Styles for the switch option container
.switch-option {
  display: flex;
  align-items: center;
  margin: 0 6px 0 6px;
}

// Styles for the switch label
.switch-label {
  flex: 1;
  font-size: 16px;
  font-family: $font-regular;
  color: #333;
}

.switch-label.disabled {
  color: #333;
}

// Styles for the switch container
.switch-container {
  width: 50px;
  height: 24px;
  border-radius: 12px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    background-color: #e0e0e0; // Disabled background color
  }
}

// Styles for the switch itself
.switch {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease-in-out;

  &.on {
    transform: translateX(26px); // Moves the switch to the right when on
    background-color: $color-blue-dark; // Blue color when switch is on
  }

  &.off {
    transform: translateX(0);
  }

  &.disabled {
    background-color: #f0f0f0; // Disabled background color for switch
  }
}
