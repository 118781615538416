@import 'src/assets/css/variables.scss';

$category-card-mobile: 149px;
$category-list-height-mobile: calc(100vh - 208px);
$category-list-height-mobile-browser: calc(100vh - 330px);
$category-list-height: calc(100vh - 80px);

$grid-companies-container-height-mobile: calc(100vh - 260px);
$grid-companies-container-height-mobile-browser: calc(100vh - 340px);
$grid-companies-container-height: calc(100vh - 180px);

$menu-height-mobile: calc(100vh - #{$bottom-bar-height} - 64px); 
.menu-key-content-container {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  // scroll-behavior: smooth;
  // -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto; /* Adjust the touch scrolling behavior */
  overscroll-behavior: none; /* Disable rubber-band effect */
  // scrollbar-width: none;
  // scroll-snap-type: y proximity;
  // scroll-snap-align: start;
  // scroll-snap-stop: always;

  .menu-section {
    scroll-snap-align: start;
    max-width: 22.625rem;
    margin: 0 auto;
  }
  #section-2 {
    min-height: 100vh;
    @media (max-width: $tablet-grid) {
      min-height: $menu-height-mobile;
    }
  }

  .swiper-companies-container {
    .swiper-container {
      z-index: 0;
    }
    .swiper-tabs-container {
      display: inline-flex;
      z-index: 1;
      width: 100%;
      background-color: $color-black;
      position: relative;
      height: 48px;
      align-items: flex-end;
      justify-content: center;
      margin: 0 auto;
      margin-top: -4px;
      .swiper-tab {
        font-family: $font-semibold;
        font-size: 15px;
        line-height: 18px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      @media (max-width: $tablet-grid) {
        max-width: 100%;
        // margin: 0;
      }
    }
    .swiper-container-horizontal > .swiper-scrollbar {
      position: relative;
      top: 0;
      left: 16px;
      width: 100%;
      max-width: calc(100% - 32px);
      height: 1px;
      background: $color-indigo;
      .swiper-scrollbar-drag {
        background: white;
        max-width: 50%;
        margin-top: -0.5px;
      }
      @media (max-width: $tablet-grid) {
        max-width: 340px;
        left: calc(((100% - 348px) / 2) + 4px);
        top: 0px;
      }
    }
    
    &.fixed {
      .swiper-tabs-container {
        position: fixed;
        top: 74px;
        left: calc(#{$sidebar-width});
        width: 100%;
        max-width: 370px;
        margin: 0;
        @media (max-width: $tablet-grid) {
          top: calc(64px + 63px);
          max-width: 22.625rem;
          left: auto;
          // left: calc((100% - 382px) / 2);
          padding: 0 16px;
        }
        .swiper-tab {
          // position: fixed;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .swiper-container-horizontal > .swiper-scrollbar {
        position: fixed;
        top: calc(64px + 57px);
        left:  calc(#{$sidebar-width} + 16px);
        width: 100%;
        max-width: 340px;
        z-index: 2;
        box-shadow: 0 0px 8px 12px rgba(34, 39, 42, 0.9);
        height: 2px;
        .swiper-scrollbar-drag {
          height: 2px;
        }
        @media (max-width: $tablet-grid) {
          top: calc(64px + 64px + 47px);
          max-width: 332px;
          left: calc(((100% - 348px) / 2) + 4px);
          margin: 0 auto;
        }
      }
      // &.is-ios- {
      //   .swiper-tabs-container {
      //     @media (max-width: $tablet-grid) {
      //       // max-width: 376px;
      //       // left: calc((100% - 376px) / 2);
      //     }
      //   }
      //   .swiper-container-horizontal > .swiper-scrollbar {
      //     @media (max-width: $tablet-grid) {
      //       max-width: 345px;
      //       left: calc((100% - 348px) / 2);
      //     }
      //   }
      // }
      .select-category-container {
        margin-top: 124px !important;
      }

      .company-container {
        margin-top: 104px !important;
      }

      .company-details-card-container {
        margin-top: 116px !important;
      }
      .expanded-company-container {
        .company-details-card-container {
          margin-top: 0 !important;
        }
      }
    }
  }

  .swiper-wrapper {
    min-height: calc(100vh - 68px);
    padding-top: 0;
    @media (max-width: $tablet-grid) {
      min-height: calc(100vh - 64px - 40px);
      // padding-bottom: 50px;
    }
  }

  .user-companies-container {
    position: relative;
    // height: calc(100vh - 185px);

    .add-company-button {
      position: absolute;
      bottom: -32px;
      background-color: $color-black;
      width: 100%;
      height: 100px;
      padding: 32px 0;
      
      @media (max-width: $tablet-grid) {
        bottom: -80px;
        &.browser {
          bottom: -80px;
        }
      }

      // border-bottom: 1px solid rgba($color-blue-extra-light, 0.16);
      &.box-shadow {
        z-index: 2;
        box-shadow: 0 -4px 8px 8px rgba(34, 39, 42, 0.9);
      }

      
    }
    .companies-top-container {
      position: relative;
      &.box-shadow {
        z-index: 2;
        box-shadow: 0 -4px 8px 8px rgba(34, 39, 42, 0.9);
      }
    }

    .grid-scroll-container {
      margin-left: 7px;
      margin-right: 7px;
      // overflow-y: auto;
      overflow-y: hidden;
      // max-height: $grid-companies-container-height;
      max-height: 100%;
      &.browser {
        // max-height: $grid-companies-container-height;
        @media (max-width: $tablet-grid) {
          // max-height: $grid-companies-container-height-mobile-browser;
        }
      }
      @media (max-width: $tablet-grid) {
        // max-height: $grid-companies-container-height-mobile;
      }
    }

    @media (min-width: $tablet-grid) {
      // height: calc(100vh - 55px);
    }
  }


  .add-providers-page-container {
    position: relative;
    width: 100%;
    // height: 100vh;
    margin: auto;
    max-width: $tablet-grid;
    transition: transform 0.5s ease-in-out;
    padding-top: 0;
    overflow: hidden;
    @media (min-width: $tablet-grid) {
      // overflow: hidden;
    }
  
    .select-category-container {
      height: calc(100vh - 200px);
      transition: transform 0.5s ease-in-out;
      overflow-y: auto;
      padding-bottom: 24px;
      &.visible {
        transform: translateY(-100vh);
      }
    }
  
  }
}


.category-container {
  width: 100%;
  overflow: hidden;
  background-color: $color-black;
  margin-top: 8px;
  .list-instruction-container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    &.box-shadow {
      z-index: 2;
      box-shadow: 0 -4px 8px 8px rgba(34, 39, 42, 0.9);
    }
    .instruction-text {
      font-family: $font-regular;
      color: white;
      font-size: $font-size-regular;
    }
  }


  .grid-container {
    display: grid;
      grid-template-columns: repeat(auto-fill, minmax($category-card-mobile, 1fr));
      /* Adjust the column width as needed */
      column-gap: 8px;
      row-gap: 16px;
      /* Adjust the gap between grid items */
      justify-content: space-between;
    }
    
    .list-scroll-container {
      // overflow-y: auto;
      overflow-y: hidden;
      // height: $category-list-height;
      height: 100%;
      z-index: 1;
  }
}


.slide-in-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-black;
  color: white;
  z-index: 3;
  box-shadow: 0 -4px 8px 8px rgba(34, 39, 42, 0.9);
  > .main-page-container {
    padding-top: 0;
  }
  .back-button-container {
    display: none;
  }

  .top-section-container {
    background-color: $color-black;
    .search-bar-container {
      .select-list-container {
        .select-list-row-container {
          .select-list {
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .search-bar-container {
    margin-top: 0;
    margin-left: 3px;
    width: 337px;
  }

  &.visible {
    .back-button-container {
      display: block;
    }
    .search-bar-container {
      margin-top: 0;
    }
  }

  &.bottom-fixed {
    .top-section-container {
      position: fixed;
      z-index: 2;
      width: 370px;
      bottom: 0;
      height: 100px;
      box-shadow: 0 -10px 8px 0px rgba(34, 39, 42, 0.9);
      @media (max-width: $tablet-grid) {
        bottom: 48px;
      }
    }
  }

  &.fixed {
    .back-button-container {
      display: block;

      @media (max-width: $tablet-grid) {
        display: none;
      }
    }

    .top-section-container {
      position: fixed;
      z-index: 3;
      width: 370px;
      top: 0;

      @media (min-width: $tablet-grid) {
        .search-bar-container {
          height: 40px;

          .select-list-container {
            height: 40px;

            .select-list-row-container {
              height: 40px;

              .select-list {
                height: 40px;
                margin-top: 0 !important;
              }
            }
          }
        }
      }

      @media (max-width: $tablet-grid) {
        top: 64px;
        width: 22.625rem;
        left: auto;
        z-index: 2;

        // max-width: 360px;
        .search-bar-container {
          max-width: 337px;
          width: 100%;
          margin: 0 auto;
        }

        // &.is-ios {
        //   // width: 22.625rem;
        // }
      }

    }
  }
}

.intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .logo-container {
    > img {
      height: 64px;
      width: 64px;
    }
  }
}

.back-button-container {
  position: relative;
  &.box-shadow {
    z-index: 2;
    box-shadow: 0 -4px 8px 8px rgba(34, 39, 42, 0.9);
  }
}

.search-bar-shortcut {
  z-index: 3;
  position: absolute;
  bottom: 12px;
  width: 100%;
  height: 64px;
  background-color: $color-black;
  @media (max-width: $tablet-grid) {
    bottom: 100px;
    height: 100px;
    &.browser {
      height: 140px;
      bottom: 165px;
    }
  }
}
