@import "src/assets/css/variables.scss";

.react-datepicker {
    font-family: $font-semibold;
    font-size: 12px;
    padding: 4px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 2px 15px 0 $color-grey-blue;

}
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__header {
    background-color: white;
    margin: 0;
    border-bottom: none;
    padding-bottom: 0;

    select {
        border: none;
        background-color: white;

        &:active, &:hover, &:focus {
            outline: none;
        }
    }

    .calendar-arrow-left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
    
        img {
            filter: $filter-black;
            height: 16px;
        }
    }
    .calendar-arrow-right {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
    
        img {
            filter: $filter-black;
            height: 16px;
        }
    }
}

.react-datepicker__day {
    font-family: $font-semibold;
    color: $color-black;
    &-names {
        .react-datepicker__day-name {
            font-family: $font-regular;
            color: $color-grey-blue;
        }
    }

    &--keyboard-selected {
        background-color: inherit;
    }
    
    &--today {
        background-color: $color-yellow-light;
        border-radius: 22px;
    }

    &--outside-month {
        color: $color-grey-blue;
    }

    &--selected {
        background-color: $color-blue;
        border-radius: 22px;
        box-shadow: 1px 2px 12px 0 rgba(0, 169, 253, 0.5);
        color: white;
        &:hover {
            background-color: $color-blue;
        }
        &:active, &:hover, &:focus {
            outline: none;
        }
    }

    &--disabled {
        color: $color-grey-blue;
    }


    &:hover {
        border-radius: 22px;
        background-color: $color-blue-light;
        color: white;
    }
}


