@import "src/assets/css/variables.scss";

.max-column-width {
  max-width: $max-column-width;
}

.sidebar-container {
  display: flex;
  float: left;
  position: static;
  height: 100vh;
	background-color: $color-indigo-dark;
    ::-webkit-scrollbar-track {
      background: $color-indigo !important;
      border: 2px solid $color-black !important;
      background-clip: padding-box; 
    }
    /* Track */
    ::-webkit-scrollbar-track:hover {
      background: $color-indigo-dark !important;
    }
  }
.bar-container {
	display: flex;
	position: relative;
	width: $sidebar-width;
	height: 100vh;
	float: left;
	margin: 0px;
	padding: 0px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: $color-indigo-dark;
	justify-content: center;
	text-align: center;
	z-index: 100;

}
.navigation-container {
	width: 100%;
	background-color: $color-indigo-dark;
	justify-items: center;
	align-items: center;
	vertical-align: middle;

  &.open {
    background-color: white;
    .sidebar-option-menu {
      .sidebar-option-button {
        .tab-notification-container > .tab-notification {
          box-shadow: $box-shadow-light;
        }
        .menu-icon-container {
          img {
            filter: $filter-grey-blue;
          }
          .nav-label {
            color: $color-grey-blue
          }
        }
      }

      .sidebar-inner-container {
        .sidebar-option-button {
          .menu-icon-container {
            img {
              filter: $filter-indigo-dark;
            }
            .nav-label {
              color: $color-indigo-dark;
            }
          }
        }
      }
    }
  }
  .sidebar-option-menu {
    width: 100%;
    background-color: $color-indigo-dark;
  
    &.open {
      background-color: white;
  
      .sidebar-inner-container {
        box-shadow: -6px 4px 4px -4px rgba(165, 173, 189, 0.9), 0px 4px 4px -3px rgba(165, 173, 189, 0.9);
        @media (max-width: $tablet-grid) {
          box-shadow: -6px 4px 4px -4px rgba(165, 173, 189, 0.9), 0px 4px 4px -3px rgba(165, 173, 189, 0.9);
        }
      }
    }
  
    .sidebar-option-button {
      height: 74px;
      height: 4.5rem;
      display: flex;
      align-items: center;
      .menu-icon-container {
        img {
          filter: $filter-white;
        }
        .nav-label {
          color: white;
        }
      }
      &.active {
        // background-image: linear-gradient(to left, $color-black, 50%, $color-black);
        background-color: white;
        box-shadow: inset 0px 9px 8px -10px black, inset 0px -9px 8px -10px black;

        .tab-notification-container > .tab-notification {
          box-shadow: $box-shadow-light;
        }
        
        .menu-icon-container {
          img {
            filter: $filter-black;
          }
          .nav-label {
            color: $color-black;
          }
        }
        &.outside {
          width: calc(100% - 4px);
          margin-left: 4px;
          .menu-icon-container {
            margin-left: -4px;
          }
        }
      }
      &.open {
        background-image: linear-gradient(to left, $color-black, 50%, $color-black);
        box-shadow: inset 0px 11px 8px -10px black, inset 0px -11px 8px -10px black;
        color: white;
        .tab-notification-container > .tab-notification {
          box-shadow: $box-shadow-dark;
        }
        .menu-icon-container {
          img {
            filter: $filter-white;
          }
          .nav-label {
            color: white;
          }
        }
        &.outside {
          width: calc(100% - 4px);
          margin-left: 4px;
          .menu-icon-container {
            margin-left: -4px;
          }
        }
      }
      &.outside {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      .tab-notification-container > .tab-notification {
        box-shadow: $box-shadow-dark;
      }
  
      &#btnSchedule {
        height: 4.5rem;
        padding: 0;
        order: 2;
        .tab-notification-container {
          top: 2px;
        }
        .menu-icon-container {
          .nav-label.outside {
            margin-top: 4px;
          }
        }
      }
  
      &#btnKeymaster {
        height: 4.5rem;
        padding: 0;
        order: 3;
        .menu-icon-container {
          .nav-label.outside {
            margin-top: 4px;
          }
        }
      }
    }
  
    .sidebar-inner-container {
      position: relative;
      width: 56px;
      margin-left: 4px !important;
      border-radius: 32px;
      box-shadow: -6px 4px 4px -4px rgba(0, 0, 0, 0.95);
      // background-image: $white-gradient;
      order: 1;
  
      
      &.last-open {
        border-bottom-right-radius: 0px;
        box-shadow: none;
      }
      .sidebar-option-button {
        background-image: linear-gradient(to left, white 50%, #ececec);
        height: 4.5rem;
        .tab-notification-container > .tab-notification {
          box-shadow: $box-shadow-light;
        }
        .menu-icon-container {
          img {
            filter: $filter-indigo;
          }
          .nav-label {
            color: $color-black;
          }
        }
  
        &#btnHome {
          height: 4.75rem;
          border-top-left-radius: 32px;
          border-top-right-radius: 32px;
          .menu-icon-container {
            .nav-label {
              margin-top: 4px;
            }
          }
        }

        &#btnProfile {
          height: 5rem;
          border-bottom-left-radius: 32px;
          border-bottom-right-radius: 32px;
          &.open {
            border-bottom-right-radius: 0px;
          }
        }
        &.active, &.open {
          width: 60px;
          background-image: linear-gradient(to left, white 50%, white);;
          box-shadow: inset 0px 11px 8px -10px rgba(165, 173, 189, 0.9), inset 0px -11px 8px -10px rgba(165, 173, 189, 0.9);
          .menu-icon-container {
            img {
              filter: $filter-black;
            }
            .nav-label {
              color: $color-black;
            }
          }
        
          &#btnProfile {
            border-bottom-left-radius: 32px;
            border-bottom-right-radius: 0;
            box-shadow:  inset 0px 11px 8px -10px rgba(165, 173, 189, 0.9), inset 0px -11px 8px -10px rgba(165, 173, 189, 0.9), -6px 2px 4px -4px rgba(0, 0, 0, 0.95);
          }
          &#btnHome {
            border-top-left-radius: 32px;
            border-top-right-radius: 0;
            box-shadow: inset 0px 11px 8px -10px rgba(165, 173, 189, 0.9), inset 0px -11px 8px -10px rgba(165, 173, 189, 0.9);
          }
        }
  
        &.open {
          background-image: linear-gradient(to left, $color-black, 50%, $color-black);
          box-shadow: inset 0px 11px 8px -10px black, inset 0px -11px 8px -10px black, -6px 2px 4px -4px ;
          .menu-icon-container {
            img {
              filter: $filter-white;
            }
            .nav-label {
              color: white;
            }
          }
  
          &#btnProfile {
            box-shadow: inset 0px 11px 8px -10px black, inset 0px -11px 8px -10px black, -6px 2px 4px -4px rgba(165, 173, 189, 0.9);
          }
          &#btnHome {
            box-shadow: inset 0px 11px 8px -10px black, inset 0px -11px 8px -10px black;
          }
        }
      }
  
    }
    ul {
      list-style-type: none;
      padding: 0px;
    }
    @media (max-width: $tablet-grid) {
      display: flex;
      position: relative;
      height: 100%;
      
      background-image: $black-gradient !important;
      .custom-stroke {
        stroke-width: 3px;
      }
      &.open {
        background-image: linear-gradient(to bottom, white, 50%, $white) !important;
        .sidebar-inner-container {
          box-shadow: $box-shadow-light;
        }
      }
      .sidebar-option-button {
        height: 42px;
        width: calc(100vw/5);
        display: flex;
        align-items: center;
        box-shadow: none;
        &#btnSchedule {
          height: 3rem;
          order: 1;
          &.open {
            height: calc(3rem + 2px);
            margin-top: -2px !important;
            box-shadow: -4px -1px 4px -5px rgba(0, 0, 0, 0.95), -11px -1px 4px -16px rgba(0, 0, 0, 0.95), inset -6px -2px 3px -4px rgba(0, 0, 0, 0.95); //, inset -7px -1px 6px -4px rgba(0, 0, 0, 0.95);
          }
          &.active {
            height: calc(3rem + 2px);
            margin-top: -2px !important;
            box-shadow: -4px -1px 4px -5px rgba(165, 173, 189, 0.75), -11px -1px 4px -16px rgba(165, 173, 189, 0.75), inset -6px -2px 3px -4px rgba(165, 173, 189, 0.75); //, inset -7px -1px 6px -4px rgba(0, 0, 0, 0.95);
          }
        }
    
        &#btnKeymaster {
          height: 3rem;
          order: 3;
          &.open {
            height: calc(3rem + 2px);
            margin-top: -2px !important;
            box-shadow: -4px -1px 4px -5px rgba(0, 0, 0, 0.95), -11px -1px 4px -16px rgba(0, 0, 0, 0.95), inset 6px -2px 3px -4px rgba(0, 0, 0, 0.95);
          }
          &.active {
            height: calc(3rem + 2px);
            margin-top: -2px !important;
            box-shadow: -4px -1px 4px -5px rgba(165, 173, 189, 0.75), -11px -1px 4px -16px rgba(165, 173, 189, 0.75), inset 6px -2px 3px -4px rgba(165, 173, 189, 0.75);
          }
        }
        .tab-notification-container > .tab-notification {
          box-shadow: $box-shadow-dark;
        }
        .logo {
          img {
            max-width: 24px;
            min-width: 24px;
            min-height: 24px;
            max-height: 24px;
          }
        }
        .menu-icon-container {
          width: 88px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .nav-label {
            line-height: 0.75rem;
            margin-top: 4px;
          }
          img {
            max-width: 32px;
            min-width: 32px;
            min-height: 32px;
            max-height: 32px;
            width: auto;
            height: auto;
        
            &.big {
              max-width: 40px;
              min-width: 40px;
              min-height: 40px;
              max-height: 40px;
            }
          }
        }
        &.active {
          height: calc(3rem + 2px);
          margin-top: -2px;
          width: 100%;

          background-color: white;
          z-index: 2;
          .menu-icon-container {
            // width: 100%;
            margin-top: 3px;
            img {
              max-width: 24px;
              min-width: 24px;
              min-height: 24px;
              max-height: 24px;
              width: auto;
              height: auto;
          
              &.big {
                max-width: 40px;
                min-width: 40px;
                min-height: 40px;
                max-height: 40px;
              }
            }
          }
        }
        &.open {
          .menu-icon-container {
            // width: 100%;
            margin-top: 3px;
            img {
              max-width: 24px;
              min-width: 24px;
              min-height: 24px;
              max-height: 24px;
              width: auto;
              height: auto;
          
              &.big {
                max-width: 40px;
                min-width: 40px;
                min-height: 40px;
                max-height: 40px;
              }
            }
          }
        }
        &.outside {
          width: calc(100vw/5);
          margin-left: 0;
          .menu-icon-container {
            margin-top: 1px;
          }
        }
        &.active.outside {
          width: calc(100vw/5);
          margin-left: 0px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        &.open.outside {
          height: calc(3rem + 2px);
          margin-top: -2px;
          width: calc(100vw/5);
          margin-left: 0px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          .menu-icon-container {
            margin-top: 3px;
          }
        }
      }
  
      .sidebar-inner-container {
        height: 3rem;
        width: auto;
        display: inline-flex;
        align-items: center;
        border-radius: 32px;
        box-shadow: $box-shadow-dark;
        order: 2;
        margin-left: 0 !important;
        &.last-open {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 24px;
          box-shadow: $box-shadow-light;
        }
        .sidebar-option-button {
          position: relative;
          border-top: 1px solid transparent; /* Make the top border transparent to apply the shadow */
          width: calc(100vw/5);
          box-shadow: none;
          background-image: $white-gradient;
          height: 3rem;
          .menu-icon-container {
            width: auto;
            img {
              filter: $filter-black;
            }
            .nav-label {
              color: $color-black;
            }
  
          }
          &#btnHome {
            order: 2;
            height: 3rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          &#btnEquipments {
            height: 3rem;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 0;
          }

          &#btnProfile {
            order: 3;
            height: 3rem;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 0;
          }

          &.active::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            clip-path: polygon(1px 0, 1px 3px, 10px 3px, 20px 0, calc(100% - 20px) 0, calc(100% - 10px) 3px, calc(100% - 1px) 3px, calc(100% - 1px) 0, 1px 0);
          //  z-index: -1; /* Ensure it is behind the main element */
            opacity: 1; /* Ensure the background is opaque */
          }
  
          &.active {
            position: relative;
            border-top: 0px;
            height: calc(3rem + 2px);
            margin-top: -2px;
            width: calc(100vw/5);
            box-shadow: -4px -1px 4px -5px rgba(165, 173, 189, 0.5), -11px -1px 4px -16px rgba(165, 173, 189, 0.5), inset 7px -1px 6px -4px rgba(165, 173, 189, 0.9), inset -7px -1px 6px -4px rgba(165, 173, 189, 0.9);
  
            > .menu-icon-container {
              width: auto;
              img {
                z-index: 2;
                filter: $filter-black;
              }
              .nav-label {
                z-index: 2;
                color: $color-black;
              }
              .white-area {
                display: block;
                transition-delay: 0.3s;
                position: fixed;
                z-index: 1;
                width: 100%;
                height: 1px;
                background-color: white;
                margin-top: -42px;
                border-bottom-left-radius: 18px;
                border-bottom-right-radius: 18px;
                box-shadow: 0 -1px 3px 1px white;
              }
            }

            &#btnHome {
              height: calc(3rem + 2px);
              margin-top: -2px;
              border-top: 0px;
              border-top-left-radius: 0;
              box-shadow: -4px -1px 4px -5px rgba(165, 173, 189, 0.5), -11px -1px 4px -16px rgba(165, 173, 189, 0.5), inset 7px -1px 6px -4px rgba(165, 173, 189, 0.9), inset -7px -1px 6px -4px rgba(165, 173, 189, 0.9);
            }
          
            &#btnProfile {
              height: calc(3rem + 2px);
              margin-top: -2px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 18px;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              box-shadow: inset 7px -1px 6px -4px rgba(165, 173, 189, 0.9), inset -6px 0px 4px -3px rgba(165, 173, 189, 0.9);
            }
            &#btnEquipments {
              height: calc(3rem + 2px);
              margin-top: -2px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 18px;
              box-shadow: -4px -1px 4px -5px rgba(165, 173, 189, 0.5), -11px -1px 4px -16px rgba(165, 173, 189, 0.5), inset 6px -2px 3px -4px rgba(165, 173, 189, .9), inset -7px -1px 6px -4px rgba(165, 173, 189, 0.9);
            }
          }
  
          &.open {
            width: calc(100vw/5);
            height: calc(3rem + 2px);
            margin-top: -2px;
            background-image: linear-gradient(to bottom, $color-black, 50%, $color-black);
            box-shadow: inset 20px 0px 8px -14px black, inset -20px 0px 8px -14px black;
            &#btnEquipments {
              height: calc(3rem + 2px);
              margin-top: -2px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 18px;
              box-shadow: -4px -1px 4px -5px rgba(0, 0, 0, 0.95), -11px -1px 4px -16px rgba(0, 0, 0, 0.95), inset 6px -2px 3px -4px rgba(0, 0, 0, 0.95), inset -7px -1px 6px -4px rgba(0, 0, 0, 0.95);
            }
            &#btnProfile {
              height: calc(3rem + 3px);
              margin-top: -2px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 20px;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              box-shadow: -4px -1px 4px -5px rgba(0, 0, 0, 0.95), -11px -1px 4px -16px rgba(0, 0, 0, 0.95), inset 6px -2px 3px -4px rgba(0, 0, 0, 0.95), inset -7px -1px 6px -4px rgba(0, 0, 0, 0.95);
            }
            > .menu-icon-container {
              img {
                filter: $filter-white !important;
              }
              .nav-label {
                color: white;
              }
            }
          }
        }
      }
    }
  }

}
.view-container {
	display: none;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  margin: 0px;
  padding: 0px;
  z-index: 60;
  transition: 0.3s;
	right: 100vw;
  left: calc(0px - (#{$sidebar-width} + #{$sidebar-open-width}));
  box-shadow: 10px 2px 25px rgba(0, 0, 0, 0.5);
  background-color: #22272a;
  overflow-y: unset;
	overflow-x: unset;
	&.open {
		transition: 0.3s;
		left: $sidebar-width;
		right: calc(100vw - #{$sidebar-width} - #{$sidebar-open-width});
	}

	.button-close-container {
    z-index: 2;
		background-color: $color-black;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: -24px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 24px;
			height: 24px;
		}
	}
}

.sidebar-option-menu.has-border::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 1px; /* Adjust the thickness of the border */
  background-color: #69696912; /* Adjust the color of the border */
}
.sidebar-option-menu.has-left-border::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 1px; /* Adjust the thickness of the border */
  background-color: #69696912; /* Adjust the color of the border */
}

.sidebar-option-button {
  justify-content: center;
  padding: 16px;
  cursor: pointer;

  img {
    max-width: 32px;
    min-width: 32px;
    min-height: 32px;
    max-height: 32px;
    width: auto;
    height: auto;
    &.big {
      max-width: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      max-height: 3rem;
    }
  }

  // @media (max-width: $tablet-grid) {
  //   .menu-icon-container {
  //     width: auto;
  //   }
  //   &:not(.active) {
  //     .menu-icon-container {
  //       &.main-menu-container {
  //         img {
  //           max-width: 32px;
  //           min-width: 32px;
  //           min-height: 32px;
  //           max-height: 32px;
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
  // }
}


.sidebar-option-button {
  position: relative;
  .tab-notification-container {
    position: absolute;
    width: 80px;
    top: 4px;

    .tab-notification {
      position: absolute;
      right: 16px;
      top: 0px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $box-shadow-light;
      .number {
        color: $color-black;
        font-size: 13px;
        font-family: $font-bold;
        &.small {
          font-size: 0.75rem;
        }
      }
    }
    @media (max-width: $tablet-grid) {
      top: 0px;
      &.fixed {
        position: fixed;
        bottom: 61px;
        right: calc(50% - 31px);
        top: unset;
      }
      .tab-notification {
        right: 0.75rem;
        top: 2px;
      }
    }
  }
}
.block-view {
  margin: 0 auto;
  overflow: auto;
  min-height: 100px;
  color: white;
  padding: 20px 5px;
}
.equipments-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.scrollable-container {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  @media (max-width: $tablet-grid) {
    max-height: calc(100vh - 205px);
    height:100%;
  }
  &.short{
    max-height: calc(100vh - 90px);
    @media (max-width: $tablet-grid) {
      max-height: calc(100vh - 328px);
    }
  }
  &.short2{
    max-height: calc(100vh - 140px);
    @media (max-width: $tablet-grid) {
      max-height: calc(100vh - 250px);
    }
  }
  &.short3{
    max-height: calc(100vh - 90px);
    @media (max-width: $tablet-grid) {
      max-height: calc(100vh - 300px);
    }
  }
  &.twoinputfields {
    @media (max-width: $tablet-grid) {
      max-height: calc(100vh - 348px);
    }
  }
  &.short4{
    max-height: calc(100vh - 90px);
    @media (max-width: $tablet-grid) {
      max-height: calc(100vh - 348px);
    }
  }
}

.fixed-buttons-wrapper {
  position: sticky; 
  bottom: 0; 
  width: 100%; 
  // max-width: $max-column-width;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; 
  background: $color-black;
  z-index: 1;
  visibility: visible;
  overflow: visible;
  &.box-shadow {
    box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, 0.95);
  }
  @media (max-width: $tablet-grid) {
    -webkit-appearance: none;
    bottom: 3.375rem;
    left: 0; 
    position: fixed; 
    &.box-shadow {
      box-shadow: 0 -4px 8px 1px rgba(0, 0, 0, 0.95);
    }
  }
  .cta-button-secondary {
    -webkit-appearance: none;
    overflow: visible;
    z-index: 11;
    margin: 0; /* Override margin: auto */
  }
}

.side-bar-menu-container-block {
  height: 100%;
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0px auto;
  max-height: 100vh;
  .service-equipment-button-container {
    .service-equipment-button {
      .button-oval {
        box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.95) !important;
      }
    }
  }
  @media (max-width: $tablet-grid) {
    max-height: calc(100vh - #{$sidebar-width});
    min-width: 0;
  }
}
.logo {
  font-size: 0px;
  cursor: pointer;
  height: 32px;
	a > img {
		width: 32px;
		height: 32px;
	}
}

.menu-list-container {
  max-width: 19.625rem;
  @media (max-width: $tablet-grid) {
    max-width: 21.25rem;
  }
}

.menu-info {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
	font-family: $font-regular;
	color: white;
  @media (max-width: $phone-grid) {
    font-size: 14px;
  }
  a {
    cursor: pointer;
    color: $color-blue;
  }
	.menu-info-title {
    font-family: $font-semibold;
    font-size: $font-size-medium;
	}
}

.menu-info-noAppointments{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 19.625rem;
  margin: auto;
  height: 106px;
  border-radius: 8px;
  border: dashed 1px $color-blue-dark;
  background-color: $color-indigo-dark;
  @media (max-width: $tablet-grid) {
    max-width: 21.25rem;
  }
}

.companies-top-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: -12px -4px 8px 8px rgba(34, 39, 42, 0.9);
  position: sticky;
  z-index: 2;
  > p {
    font-size: 16px;
    line-height: 20px;
    font-family: SourceSansPro-SemiBold;
    color: white;
  }
  .back-button {
    position: absolute;
    top: 0;
    color: $color-blue;
  }

  .search-input-container {
    height: 40px;
    border-bottom: 1px solid rgba(white, 0.7);
    padding-bottom: 0.75rem;
    align-items: center;
    &.focus {
      border-bottom-color: rgba($color-orange, 0.7);
    }
    > span > img {
      height: 32px;
      width: 32px;
      &.small {
        width: 16px;
        height: 16px;
      }
    }
    .search-input {
      background-color: $color-black;
      color: white;
      font-size: 16px;
      border: none;
      &::placeholder {
        font-family: SourceSansPro-Light;
        color: white;
        opacity: 0.7;
      }
    }
  }
}

.scroll-base-container {
  position: fixed;
  bottom: -2px;
  width: 370px;
  left: $sidebar-width;
  z-index: 3;
  height: 2px;
  box-shadow:  0 -1px 6px 0 rgba(0, 0, 0, 0.95);

  // box-shadow: 0 26px 53px 32px rgba(150, 150, 150, 1);

  &.end {
    // transition-delay: 3s;
    transition-duration: 1s;
    box-shadow:  0 50px 6px 0 rgba(0, 0, 0, 0.95);
  }

  @media (max-width: $tablet-grid) {
    left: 0;
    width: 100%;
  }
}

.grid-container--companies {
  .company-card-container {
    position: relative;
    padding: 16px 8px;
    width: 100%;
    max-width: 50%;
    height: auto;
    color: white;
    border-radius: 16px;
    text-align: center;
    text-decoration: none;

    .card-company-name {
      margin-top: 8px;
      font-family: SourceSansPro-Bold;
      font-size: 14px;
      line-height: 1.25;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .card-company-logo-container {
      background-color: white;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      padding: 3px;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    .tab-notification-container {
      position: absolute;
      top: 5px;
      right: 0px;
      width: 100%;

      &.counter-alert {
        top: 10px;
        right: 4px;
      }

      .tab-notification {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -1px 2px 5px 0 rgba(165, 173, 189, 0.7);

        .number {
          color: $color-black;
          font-size: 13px;
          font-family: $font-bold;

          &.small {
            font-size: 0.75rem;
          }
        }

        &.counter-alert {
          width: 0.875rem;
          height: 0.875rem;
          padding: 0.063rem;
          background-color: $color-orange;
          box-shadow: -1px 2px 5px 0 rgba(88, 89, 91, 0.7);
          z-index: 100;
        }

      }

      @media (max-width: $tablet-grid) {
        top: 0px;

        .tab-notification {
          right: 0px;
          top: 2px;
        }
      }
    }

    @media (min-width: $desktop-grid) {
      &:hover {
        background-color: $color-indigo;
      }
    }
  }
}
.drawer-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 25;
}
.drawer-layer-open {
  pointer-events: auto;
}
.drawer-layer::before {
  content: "";
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2c3e50;
  opacity: 0;
  transition: opacity 300ms linear;
  will-change: opacity;
}
.drawer-layer-open::before {
  opacity: 0.4;
}

@media (max-width: $tablet-grid) {
  .sidebar-container {
    position: relative;
    display: block;
    width: 100%;
    height: auto;      
  }
  @media (max-height: 660px) {
    .bar-container.mobileKeyboardVisible {
      display: none; /* Hide when viewport height <= 660px */
    }
  }
  .bar-container {
    position: fixed;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: $bottom-bar-height;
    overflow: hidden;
    // background-color: transparent;
    z-index: 100;
		bottom: 0;
    &.mobileKeyboardVisible{
      height: 0px;
    }
    &.box-shadow {
      box-shadow:  0 -4px 8px 1px rgba(0, 0, 0, 0.95);
      // box-shadow: 0 26px 53px 32px rgba(150, 150, 150, 1);

      &.end {
        // transition-delay: 3s;
        transition-duration: 1s;
        box-shadow:  0 50px 8px 1px rgba(0, 0, 0, 0.95);
        // box-shadow: 0 100px 53px 32px rgba(150, 150, 150, 1);
      }
    }

    &.bottom-shadow {
      box-shadow: $bottom-shadow;
      &-home {
        box-shadow: $bottom-shadow-home
      }
      &-schedule {
        box-shadow: $bottom-shadow-schedule
      }
      &-items {
        box-shadow: $bottom-shadow-items
      }
      &-profile {
        box-shadow: $bottom-shadow-profile
      }
    }

    // &.open {
    //   box-shadow: none;
    // }
  }
  .navigation-container {
    display: inline-flex;
    align-items: center;
		height: $bottom-bar-height;
    padding: auto;
    margin: auto;
    // position: fixed;
    // background-color: transparent;
  }
  .logo {
    display: inline-flex;
    margin: 0;
    font-size: 0px;
    font-family: "SourceSansPro-Regular";
    text-align: center;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
		i.fa {
			width: 25px;
			color: $color-blue;
			font-size: 28px;
			margin-left: 10px;
		}
		a {
			font-family: "SourceSansPro-Regular";
			text-align: center;
			font-size: 20px;
			color: #ffffff;
			cursor: pointer;
			text-decoration: none;
			display: flex;
			align-items: center;
			img {
				width: 32px;
				height: 32px;
			}
		}
  }

  .view-container {
    width: 100%;
    height: 0px;
    display: flex;
    position: fixed;
    margin: 0px;
    padding: 40px 0 0 0;
    z-index: 60;
    background-color: #22272a;
		top: 100vh;
    left: 0px;
    transition: 0.3s;
		&.open {
			width: 100%;
			height: calc(100vh - 64px - #{$bottom-bar-height});
			top: 64px;
			left: 0px;
			padding: 0;
			transition: 0.3s;
		}
		.button-close-container {
			position: absolute;
			top: -24px;
			left: -24px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 24px;
				height: 24px;
			}
      &.mobileKeyboardVisible{
        height: 0px;
      }
		}
    @media (max-height: 660px) {
      .button-close-container.mobileKeyboardVisible {
        display: none; /* Hide when viewport height <= 660px */
      }
    }
  }

  .viewMenu {
    display: flex;
    width: auto;
    margin: 5% auto 5% auto;
    padding: 0px;
    justify-content: center;
    text-align: center;
  }
  .grid-scroll-container {
    margin-right: 0;
  }
  .add-company-button {
    position: relative;
  }
}

/* CSS for the icons. Filter black->color https://codepen.io/sosuke/pen/Pjoqqp */
.icon-equip-tint {
  filter: invert(66%) sepia(73%) saturate(5914%) hue-rotate(174deg) brightness(105%) contrast(106%);
}
.icon-equip-active-tint {
  filter: invert(100%) saturate(100%) brightness(1000%) contrast(100%);
}

.nav-label {
  font-size: 0.75rem;
  color: $color-grey-blue;
  font-family: $font-semibold;
  line-height: 16px;
  &.inner {
    color: $color-indigo;
  }
  &.active {
    color: white;
  }
}

.bottom-shadow {
  &-left {
    position: absolute;
    top: 0;
    width: 100%;
    box-shadow:  0 -4px 8px 1px rgba(0, 0, 0, 0.95);
  }

  &-right {
    box-shadow: 0 -2px 6px 1px #000;
  }
}


