@import 'src/assets/css/variables.scss';
.row-list-container {
    margin: 24px auto auto auto;
    background-color: $color-blue-extra-light;
    border-radius: 13px;
    &.tab-left {
        border-top-left-radius: 0;
    }
    &.tab-right {
        border-top-right-radius: 0;
    }
    &.open {
        box-shadow: 0 3px 13px 1px $color-grey-blue;
    }

    @media (max-width: $tablet-grid) {
        margin: 16px auto 24px;
        &.open {
            box-shadow: 0 2px 8px 0px $color-grey-blue;
        }
    }

    &.container-kss {
        background-color: $color-white;

        @media (max-width: $tablet-grid) {
            background-color: $color-blue-extra-light;
        }
    }
}
.row-list-title-container,
.row-list-subtitle-container {
    font-family: SourceSansPro-Semibold;
    font-size: 18px;
    color: $color-black; 
    text-align: left;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    > img.icon {
        width: 24px;
        height: 24px;
    }
}

.row-list-subtitle-container {
    height: 24px;
    align-items: center;
}

.open-grid-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.grid-container {
    padding: 8px 16px 16px 16px;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    .children-container {
        padding-top: 8px;
        height: 100%;
        // overflow: hidden;
    }
    &--companies {
        @extend .grid-container;
        max-width: calc(#{$sidebar-open-width} - 48px);
        min-width: auto;
        align-items: flex-start;
    }
    @media (max-width: $tablet-grid) {
        padding: 8px 8px 16px 8px;
        .children-container {
            justify-content: center;
            padding: 8px 0 16px 0;
        }
    }
    @media (min-width: $largeDesktop-grid) {
        .children-container {
            text-align: left;
        }
    }
}

.grid-container-closed {
    height: 0;
    padding: 0;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    .children-container {
        height: 0;
        overflow: hidden;
    }
    &.chat {
        height: auto;
        .children-container {
            height: auto;
        }
    }
}

.divtest {
    width: 300px;
    height: 160px;
    margin: 10px;
    background-color: white;
}

.row-list-container .row-list-title-container {
    .row-list-title-counter-alert {
        margin: 0px 0px auto 4px;
        width: 0.5rem;
        height: 0.5rem;
        padding: 0.063rem;
        border-radius: 100%;
        background-color: #ff882b;
    }

    .row-list-counter-message {
        font-family: "SourceSansPro-Regular";
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: $color-black;
        margin: 0.2rem 0 0 0.5rem;
        @media (max-width: $tablet-grid) {
            margin: 0.375rem 0 0 0.5rem;
        }


        .row-list-counter-number {
          font-weight: 600;
          color: $color-orange-alt; //slighlty darker than orange because of anti-aliasing
          font-size: 0.9rem;
        }
    }
    .swiper-title-counter {
        margin: 0rem 0 0.125rem 0.25rem;
        font-family: $font-regular;
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $color-black;
      }
}

.message-counter-container {
    position: absolute;
    display: inline-block;
    transform: translate(252px, -42%);
    z-index: 1000;
    @media (max-width: $desktop-grid) {
        transform: translate(218px, -42%);
    }
}

.message-counter-icon {
    width: 28px;
    height: 28px;
}

.message-counter-number {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-56%, -43%);
    color: #e8b807;
    font-size: 15px;
}
