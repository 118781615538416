@import "src/assets/css/variables.scss";

$service-card-x: 149px;
$service-card-y: 96px;
$service-card-container: 652px;
$step-summary-container: 652px;

.schedule-page-container {
  text-align: center;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 72px);

  @media (max-width: $tablet-grid) {
    overflow-y: hidden;
    max-height: 100%;
  }

  .schedule-service-card {
    width: $service-card-x;
    height: 100%;
    min-height: $service-card-y;
    margin: auto;
    background-color: $color-blue-extra-light;
    border-radius: 13px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .schedule-service-name {
      text-align: center;
      color: $color-black;
      font-family: $font-regular;
      line-height: 18px;
      font-size: $font-size-regular;
    }

    &:hover,
    &:active,
    &.active {
      box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);

    }

    &.active {
      .schedule-service-name {
        color: $color-blue;
      }
    }

    &.disabled {
      .schedule-service-name {
        text-align: center;
        color: $color-grey-dark;
        font-family: $font-regular;
        line-height: 18px;
        font-size: $font-size-regular;
      }
    }
  }
  .service-equipment-data {
    .service-equipment-data-row {
      display: flex;
      align-items: center;
      width: 50%;

      img {
        width: 16px;
        max-height: 16px;
      }
    }
  }
}
.payment-methods {
  text-align: start;

  .grey-box-container {
    max-width: 100%;
  }
}

.payment-methods h3 {
  color: $color-black;
  text-align: left;
  font-size: 22px;
  line-height: 30px;
  font-family: $font-semibold;
  @media (max-width: $tablet-grid) {
    font-size: 18px;
}
}

.methods-list {
  display: flex;
  flex-direction: column;

  .method {
    label {
      font-size: 16px;
    }
  }
  input[type="checkbox"] {
    margin-right: 10px;
  }
}
.logo-box {
  position: relative;
  width: 54px;
  height: 40px;
  border: 1px solid $color-grey-extra-light;
  border-radius: 4px;
  background-color: white;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  .credit-card {
    position: relative;
    width: 54px;
    height: 40px;
    .band {
      position: absolute;
      background-color: $color-indigo-dark;
      top: 8px;
      width: 100%;
      height: 6px;
    }
    .number-code {
      position: absolute;
      top: 24px;
      left: 4px;
      font-size: 8px;
      color: $color-indigo-dark;
    }
    .code-ref {
      position: absolute;
      background-color: $color-indigo-dark;
      top: 28px;
      width: 12px;
      right: 4px;
      height: 4px;
    }
  }
}
