@import "src/assets/css/variables.scss";


.notifications-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 0px;
  max-width: 0px;
  height: 100vh;
  // box-shadow: -2px 2px 7px 2px rgba(165, 173, 189, 0.75);
  background-color: #ffffff;
  z-index: 101;
  transition: 0.3s;

  &.open {
  	transition: 0.3s;
    width: 362px;
    max-width: 90vw;
    box-shadow: -2px 2px 7px 2px rgba(165, 173, 189, 0.75);
    
    @media (max-width: 768px) {
      transition: 0.3s;
      width: 100%;
      max-width: 100%;
    }
    .button-close-container {
      transition: 0.3s;
      transform: translateX(-50%);
      box-shadow: -2px 2px 7px 2px rgba(165, 173, 189, 0.75);
    }
  }

  .button-close-container {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0);
    transition: 0.3s;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    img {
      z-index: 3;
      width: 1.5rem;
      height: 1.5rem;
    }

    @media (max-width: 768px) {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -8px;
      width: 32px;
      height: 52px;
      background-color: #fff;
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      width: 16px;
      height: 52px;
      background-color: #fff;
      z-index: 2;
    }
  }

  .notifications-container-group {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 362px;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100vw;
    }

    .notifications-container-header {
      width: 100%;
      display: flex;
      flex: 0 0 auto;
      height: 48px;
      max-height: 48px;
      min-height: 48px;
      background-color: $color-white;
      cursor: pointer;

      .notifications-container-button {
        flex: 0 0 auto;
        margin: auto;
      }
      .notifications-container-title {
        flex: 1 1 auto;
        font-family: $font-semibold;
        font-size: 16px;
        line-height: 18px;
        color: $color-black;
        margin: auto 0 auto 8px;
        text-align: left;
      }
    }

    .notifications-container-list {
      width: 100%;
      flex: 1 1 auto;
      height: auto;
      overflow: hidden;
      
      .notifications-container-list-item {
        position: relative;
        // min-height: 56px;
        font-family: $font-semibold;
        font-size: 0.875rem;
        line-height: 1.29;
        color: $color-black;
        background-color: $color-white;
        margin: 4px 16px;
        padding: 4px 0px;
        text-align: left;
        border-radius: 4px;
        cursor: pointer;
        box-sizing: border-box;

        &.notifications-container-list-item-unread {
          background-color: $color-blue-extra-light;
        }

        .notifications-container-list-item-title {
          font-family: SourceSansPro-Regular;
          font-size: 1.1rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          color: $color-black;
          margin-bottom: 4px;
        }

        .notifications-container-list-item-text {
          font-family: SourceSansPro-Regular;
          font-size: 1.1rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          color: $color-grey-dark;
          padding-right: 1.5rem;
          margin-bottom: 4px;
        }
        .notifications-container-list-item-delete {
          position: absolute;
          right:0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          padding: 16px 0 16px 16px;
        }
      }

      .notifications-container-list-item::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-grey-blue;
        opacity: 0.32;
        margin-bottom: -3.75px;
        z-index: 1;
      }

      .notification-item-no-notifications {
        text-align: center;
        padding-top: 32px;
      }
    }
    
    .notifications-container-footer {
      width: 10rem;
      text-align: center;
      margin: 2.5rem auto;
    }
  }
}

.notifications-sidebar-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
}
.notifications-sidebar-layer-open {
  pointer-events: auto;
}
.notifications-sidebar-layer::before {
  content: "";
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2c3e50;
  opacity: 0;
  transition: opacity 300ms linear;
  will-change: opacity;
}
.notifications-sidebar-layer-open::before {
  // opacity: 0.4;
  opacity: 0;
}


.notifications-button {
  position: relative;
  display: inline-block;
  width: 3.943rem;
  height: 3.438rem;
  overflow: hidden;
}

.notifications-button .oval {
  position:absolute;
  bottom: 0;
  left: 0;
  width: 7.886rem;
  height: 6.876rem;
  padding: 0.375rem 0.625rem 1.188rem 1.443rem;
  box-shadow: inset 3px -3px 3px 0 #000;
  background-image: linear-gradient(to bottom, $color-indigo, $color-indigo-dark 54%, $color-black);
  border-radius: 60%;
  cursor: pointer;
}
.notifications-button img {
  position: absolute;
  width: 24px; 
  height: 24px;
  right: .75rem; 
  top: 0.75rem; 
  cursor: pointer;
}
.notifications-alert {
  position: absolute;
  right: 0.375rem;
  top: 0.15rem;
  min-width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .number {
    color: $color-black;
    font-family: $font-bold;
    font-size: 0.81rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
  }
}