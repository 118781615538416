@import 'src/assets/css/variables.scss';
$blue: $color-blue;
.modal-main-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    padding-top: 5%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 100;

    @media (max-width: $tablet-grid) {
        //padding-top: 64px;
        padding-top: 40%;
    }

    .modal-container {
        text-align: center;
        position: relative;
        max-width: 651px;
        min-width: 450px;
        display: block;
        border-radius: 6px;
        box-shadow: -1px 2px 8px 0 rgba(165, 173, 189, 0.4);
        background-color: white;
        animation-name: animatetop;
        animation-duration: 0.6s;
        max-height: calc(100vh - 10%);
        overflow-y: auto;
        @media (max-width: $tablet-grid) {
            width: 95%;
            min-width: 0;
        }
        &.occupyScreen {
            overflow-y: hidden;
            max-height: calc(100vh - 80px);
        }
        .modal-area-container {
            max-height: 100%;
            overflow-y: hidden;
        }
    }

    .modal-icon-container {
        width: 100%;
        > img {
            height: 60px;
            width: 60px;
        }
    }

}

.modal-main-media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
    z-index: 100;

    .modal-media-container {
        position: relative;
        max-width: 651px;
        display: block;
        border-radius: 6px;
        box-shadow: -1px 2px 8px 0 rgba(165, 173, 189, 0.4);
        animation-name: animatetop;
        animation-duration: 0.4s;
        @media (max-width: $tablet-grid) {
            width: 95%;
        }
        background-color: black;
    
        .arrow-section {
            height: auto;
            top: calc(50% + 28px);
            width: 100%;
            // z-index: 1;
            position: absolute;
            i.inverted.circular.icon {
                background-color: $blue !important;
            }
            i.icon {
                position: absolute;
                bottom: 0;
                cursor: pointer;
                &.left {
                    left: -70px;
                }
                &.right {
                    right: -70px;
                }
                &.chevron.left:before,
                &.chevron.right:before {
                    color: $blue;
                }
            }
            @media (max-width: $tablet-grid) {
                .arrow-section {
                    top: calc(50% + 21px);
                    height: auto;
                }
                i.icon {
                    font-size: 3em;
                    &.left {
                        left: 0;
                    }
                    &.right {
                        right: 0;
                    }
                }
            }
        }
    }
}

.modal-main-cookies-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 100;
    .modal-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        background-color: #EAEDF3;
        animation-name: animatebottom;
        animation-duration: 0.4s;

        .modal-area-cookies-container {
            width: 100%;
            max-height: calc(100vh - 22px);
            overflow-y: hidden;
            margin: auto;
            display: block;
            .modal-action-container {
                display: block;
            }
        }
    }
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

@keyframes animatebottom {
    from {bottom:-300px; opacity:0}
    to {bottom:0; opacity:1}
}

.modal-media-header {
    width: 100%;
    height: 0;
}

img.icon,
i.icon {
    z-index: 10;
    cursor: pointer;
    &.times {
        position: absolute;
        right: 0;
        top: 5px;
        &:before {
            color: $blue;
        }
    }
}



.icon-close,
.icon-media-remove,
.icon-media-close {
    height: 24px;
    width: 24px;
    top: 8px;
    right: 8px;
    margin: 5px;
    cursor: pointer;
    position: absolute;
    z-index: 1000;
}

.icon-media-remove {
    left: 8px;
}

.icon-file-remove {
    position: relative;
    height: 16px;
    width: 16px;
    cursor: pointer;
    z-index: 1000;
}
.icon-close {
    width: 16px;
    height: 16px;
    top: 5px;
    right: 5px;
}

.modal-area-container {
    width: 100%;
    max-height: calc(100vh - 22px);
    overflow-y: auto;
    margin: auto;
    display: block;
}
.modal-title-text,
.modal-sub-title-text {
    font-family: 'SourceSansPro-Regular';
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin: 15px auto;
    padding-left: 20px;
    padding-right: 20px;
    color: $color-black;
    @media (max-width: $tablet-grid) {
        font-size: 25px;
        line-height: 25px;
    }

    @media (max-width: $phone-grid) {
        font-size: 23px;
        line-height: 23px;
    }
}

.modal-sub-title-text {
    font-size: 20px;
    line-height: 20px;
}


.modal-message-container {
    font-family: 'SourceSansPro-Light';
    margin: 20px auto;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: $color-black;
    @media (max-width: $phone-grid) {
        padding-left: 20px;
        padding-right: 20px;
    }

    a {
        color: $color-blue;
        font-size: 18px;
        overflow-wrap: break-word;;
    }
}
.modal-sub-message-container {
    font-family: 'SourceSansPro-Light';
    margin: 5px auto 10px;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: $color-black; 
}
.modal-section {
    .modal-message-container {
        text-align: left;
    }
}
.modal-action-container {
    margin: auto auto 20px auto;
    padding-left: 35px;
    padding-right: 35px;
    display: inline-flex;
    @media (max-width: $phone-grid) {
        flex-direction: column;
    }
    &.flex-column {
        flex-direction: column;
    }
    .button-container {
        min-width: 200px;
        max-width: 250px;
        width: 100%;
        height: 38px;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 19px;
        border: 1px solid $color-blue;
        background-color: #FFFFFF;
        color: $color-blue;
        font-size: 17px;
        font-family: 'SourceSansPro-Regular';
        cursor: pointer;
    }
    .submit-container {
        position: relative;
        width: 100%;
        max-width: 250px;
        min-width: 200px;
        height: 38px;
        align-items: center;
        justify-content: center;
        border-radius: 19px;
        border: 1px solid $color-blue;
        background-color: $color-blue;
        box-shadow: 1px 4px 12px $color-blue;
        color: #FFFFFF;
        font-size: 17px;
        font-family: 'SourceSansPro-Regular';
        cursor: pointer;
    }
}
.modal-media-photo-container{
    width: 100%;
    max-height: 95vh;
    height: auto;
    object-fit: contain;
    @media (max-width: $tablet-grid) {
        height: 95vh;
    }
}

.detail-AreaRow{
    width: 95%;
    margin: 5px auto;
    display: flex;
    justify-content: center;
}

.detail-AreaLabel{
    width: 50%;
    text-align: left;
    color: $color-black;
    font-size: 19px;
    font-family: 'SourceSansPro-Regular';
    font-weight: 700;
    margin: auto;
    @media screen and (max-width: $tablet-grid) {
        font-size: 17px;
    }
}

.detail-InputContainer {
    @media (max-width: $phone-grid) {
        width: auto !important;
    }
    margin-right: 0px !important;
}

.detail-AreaText{
    width: 25%;
    color: $color-black;
    margin: auto 20px;
    font-size: 16px;
    text-align: left;
    font-family: 'SourceSansPro-Light';
}

@media screen and (max-width: $tablet-grid) {
    .modal-action-container{
        display: block;
    }
}

.ReactModalPortal {
    .ReactModal__Overlay.ReactModal__Overlay--after-open {
      //z-index: 11 !important;
      z-index: 1000 !important;
      background-color: rgba($color-black, 0.8) !important;
      width: 100%;
      display: flex;
      overflow-y: auto;
  
      .ReactModal__Content.ReactModal__Content--after-open {
        position: relative !important;
        background-color: white !important;
        box-shadow: -1px 2px 8px 0 rgba(165, 173, 189, 0.4);
        border-radius: 6px !important;
        border: 0 !important;
        padding: 24px 16px !important;
        max-height: 650px;
        max-width: 650px;
        margin: 10vh auto auto;
        width: 90%;
        inset: 0 !important;
        height: auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        @media (min-width: $phone-grid) {
          padding: 24px !important;
          max-height: 425px;
          max-width: 650px;
        }
      }
    }
  }