@import 'src/assets/css/variables.scss';
.symptoms-section-container {
    width: 100%;
    @media (max-width: $tablet-grid) {
        border-radius: 0;
    }

    .symptoms-main-container {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
        overflow: hidden;
    }
}

.symptoms-empty-list{
    color: $color-black;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    font-family: 'SourceSansPro-Light';
}
.symptoms-title {
    color: $color-black;
    text-align: left;
    font-size: 22px;
    line-height: 30px;
    font-family: 'SourceSansPro-Semibold';
}

.symptoms-header-container {
    width: 100%;
    height: 32px;
    display: inline-flex;
    justify-content: space-between;
    border-radius: 4px;
    align-items: center;
    list-style-type: none;
    background-color: $color-indigo-dark;
    color: white;
    padding: 5px 12px;
    &.green {
        background-color: $color-green;
    }
    .symptoms-header-title {
        font-family: SourceSansPro-SemiBold;
        font-size: 16px;
        > span {
            font-family: SourceSansPro-Regular;
            font-size: 12px;
        }
    }
}
.symptoms-header-icon{
    width: 21px;
    margin: auto 10px;
    float: left;
}

.symptoms-header-text{
    font-family: SourceSansPro-SemiBold;
    font-size: 18px;
}

@media screen and (max-width: $tablet-grid) {
    .symptoms-section-container {
        margin-top: 0;
    }
    .symptoms-title {
        font-size: 18px;
    }
}
