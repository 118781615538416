@import 'src/assets/css/variables.scss';

.requirement-list-container {
    width: 100%;
    height: auto;
    display: flex;

    .list-text-container {
        width: auto;
        height: auto;
        display: block;

        .list-text {
            font-family: $font-light;
            font-size: $font-size-medium;
            text-align: left;
        }
    }
}




