@import 'src/assets/css/variables.scss';

$company-card-width: 145px;
$company-card-height: 100%;

$logo-width: 130px;
$logo-height: 48px;
$current-logo-height: 80px;
.company-card {
  position: relative;
  width: $company-card-width;
  height: $company-card-height;
  margin: auto;
  //margin-top: 0px;
  //align-self: flex-start; /* Align at the top of each grid position */
  .company-logo{
    background-color: white;
    width: $logo-width;
    height: $logo-height;
    border-radius: 4px;
    border: solid 1px rgba(165, 173, 189, 0.3);
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2px 4px;
    > img {
      height: 100%;
      width: auto;
      max-width: 100%;
      max-height: $logo-height;
      object-fit: contain;
    }

    .add-button-container {
      position: absolute;
      bottom: -12px;
      right: -14px;
      width: 28px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.4);
      background-image: $black-gradient;
      > img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .company-info {
    width: 130px;
  }
  .company-name {
    text-align: left;
    color: white;
    font-family: $font-bold;
    line-height: 16px;
    font-size: $font-size-small;
    overflow: hidden;
    text-overflow: ellipsis;
    display:-webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    &.details {
      color: $color-black;
      -webkit-line-clamp: 2;
    }
  }
  .company-localization {
    text-align: left;
    color: white;
    font-family: $font-regular;
    line-height: 18px;
    font-size: $font-size-small;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    color:  $color-blue;  
    cursor: pointer;
    
    
    &.small {
        font-size: $font-size-small;
    }

    &.details {
      color: $color-black;
    }

    .distance {
      font-family: $font-regular;
      font-size: $font-size-small;
      color: white;
      text-align: right;
      white-space: nowrap;
    }
  }
}

.company-name-logo {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  color: $color-black;
  font-family: $font-bold;
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* Limits the text to 3 lines */
  text-overflow: ellipsis;
  word-break: break-word; /* Allows words to break */
  hyphens: auto; /* Hyphenates words according to language rules */

  &.big {
    line-clamp: 3;
    -webkit-line-clamp: 3; /* Limits the text to 3 lines */
  }
}

.expanded-company-container {
  .company-name {
    font-family: $font-bold;
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.38;
    color: white;
    &.details {
      color: white;
      &.dark {
        color: $color-black;
        -webkit-line-clamp: 3;
      }
    }
  }

  .company-logo {
    height: $current-logo-height;
    align-items: center;
    position: relative;
    > img {
      height: auto;
      max-height: $current-logo-height;
    }
    .tab-notification-container {
      position: absolute;
      top: 5px;
      right: 0px;
      width: 100%;

      &.counter-alert {
        top: 10px;
        right: 4px;
      }

      .tab-notification {
        position: absolute;
        right: -12px;
        top: -16px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -1px 2px 5px 0 rgba(165, 173, 189, 0.7);

        .number {
          color: $color-black;
          font-size: 13px;
          font-family: $font-bold;

          &.small {
            font-size: 12px;
          }
        }

        &.counter-alert {
          width: 0.875rem;
          height: 0.875rem;
          padding: 0.063rem;
          background-color: $color-orange;
          box-shadow: -1px 2px 5px 0 rgba(88, 89, 91, 0.7);
        }

      }
    }
  }
}