// GRID
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// FONT
$font-regular: 'SourceSansPro-Regular';
$font-light: 'SourceSansPro-Light';
$font-semibold: 'SourceSansPro-Semibold';
$font-bold: 'SourceSansPro-Bold';
$newFont: 'Source Sans Pro', sans-serif;
$font-size-x-small: 10px;
$font-size-small: 0.75rem;
$font-size-regular: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-x-large: 22px;


//GENERAL
$sidebar-width: 4rem;
$bottom-bar-height: 3.375rem;
$sidebar-open-width: 22.625rem;
$scroll-width: 7px;
$max-column-width: 19.625rem;
$max-column-width-mobile: 21.25rem;

// GRID BV BREAKPOINTS
$view-padding: calc(48px + #{$sidebar-width});
$mobile-view-padding: (18px * 2);
$grid-padding: 8px * 2;
$card-margin: 16px;
$card-width: 308px + $card-margin;

$breakpoint-1-view: (1 * $card-width) + $grid-padding + $mobile-view-padding;
$breakpoint-2-view: (2 * $card-width) + $grid-padding + $mobile-view-padding;
$breakpoint-3-view: (3 * $card-width) + $grid-padding + $view-padding + $scroll-width;
$breakpoint-4-view: (4 * $card-width) + $grid-padding + $view-padding + $scroll-width;
$breakpoint-5-view: (5 * $card-width) + $grid-padding + $view-padding + $scroll-width;
$breakpoint-6-view: (6 * $card-width) + $grid-padding + $view-padding + $scroll-width;

$breakpoint-1-width: $breakpoint-1-view - $mobile-view-padding;
$breakpoint-2-width: $breakpoint-2-view - $mobile-view-padding;
$breakpoint-3-width: $breakpoint-3-view - ($view-padding + $scroll-width);
$breakpoint-4-width: $breakpoint-4-view - ($view-padding + $scroll-width);
$breakpoint-5-width: $breakpoint-5-view - ($view-padding + $scroll-width);
$breakpoint-6-width: $breakpoint-6-view - ($view-padding + $scroll-width);

$largeDesktop-grid: map-get($grid-breakpoints, xl);
$desktop-grid: map-get($grid-breakpoints, lg);
$tablet-grid: map-get($grid-breakpoints, md);
$phone-grid: map-get($grid-breakpoints, sm);

// COLORS
$body-background-color: #EAEDF3;
$text-white: #fff;
$white-grey: #F4F6F9;
$white: #ececec;

$color-white: #fff;
$color-black: #22272a;
$color-blue-dark: #0196e0;
$color-blue: #00A9FD;
$color-blue-light: #b7cde3;
$color-blue-extra-light: #f5f6fa;
$color-grey-blue: #a5adbd;
$color-grey-dark: #7e7e7e;
$color-grey-light: #b2b2b2;
$color-grey-extra-light: #d1d1d1;
$color-indigo: #384654;
$color-indigo-dark: #31383f;
$color-green: #00bb5b;
$color-orange: #ff882b;
$color-orange-alt: #ff8020;
$color-red: #f85359;
$color-yellow-light: #f5edc9;
$color-yellow: #fdd219;
$color-red-light: #f6d1ce;
$color-green-light: #c6edd9;
$blue-gradient: linear-gradient(to bottom, $color-blue, $color-blue-dark);
$white-gradient: linear-gradient(to bottom, white 63%, $white);
$black-gradient: linear-gradient(to bottom, $color-indigo, $color-indigo-dark 54%, $color-black);
$box-shadow-light: 0 2px 4px 1px rgba(165, 173, 189, 0.75);
$box-shadow: 0 2px 4px 1px rgba(165, 173, 189, 0.95);
$box-shadow-dark: 0 2px 4px 1px rgba(0, 0, 0, 0.95);

$bottom-shadow-home: calc(-50vw - calc(100vw/5/2)) -1px 6px 0 rgba(0, 0, 0, 0.4), calc(50vw + calc(100vw/5/2)) -1px 6px 0 rgba(0, 0, 0, 0.4);
$bottom-shadow-schedule: calc(100vw/5) -1px 6px 0 rgba(0, 0, 0, 0.4);
$bottom-shadow-items: calc(-100vw + calc(100vw/5) + 0.25rem) -1px 6px 0 rgba(0, 0, 0, 0.4), calc((2*100vw/5)) -1px 6px 0 rgba(0, 0, 0, 0.4);
$bottom-shadow-profile: calc(-100vw + calc(3*100vw/5) + 0.25rem) -1px 6px 0 rgba(0, 0, 0, 0.4), calc(100vw - calc(100vw/5) + 0px) -1px 6px 0px rgba(0, 0, 0, 0.4);
$bottom-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.9);

$filter-blue: brightness(0) saturate(100%) invert(49%) sepia(55%) saturate(3506%) hue-rotate(171deg) brightness(103%) contrast(102%);
$filter-blue-light: brightness(0) saturate(100%) invert(89%) sepia(4%) saturate(2293%) hue-rotate(181deg) brightness(92%) contrast(93%);
$filter-blue-extra-light: brightness(0) saturate(100%) invert(93%) sepia(8%) saturate(514%) hue-rotate(199deg) brightness(104%) contrast(95%);
$filter-blue-dark: brightness(0) saturate(100%) invert(55%) sepia(52%) saturate(7052%) hue-rotate(175deg) brightness(98%) contrast(99%);
$filter-black: brightness(0) saturate(100%) invert(9%) sepia(17%) saturate(622%) hue-rotate(159deg) brightness(87%) contrast(84%);
$filter-white: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(265deg) brightness(105%) contrast(101%);
$filter-grey-dark: brightness(0) saturate(100%) invert(58%) sepia(6%) saturate(0%) hue-rotate(200deg) brightness(83%) contrast(90%);
$filter-grey-blue: brightness(0) saturate(100%) invert(63%) sepia(2%) saturate(1592%) hue-rotate(177deg) brightness(111%) contrast(87%);
$filter-orange: brightness(0) saturate(100%) invert(70%) sepia(30%) saturate(4671%) hue-rotate(337deg) brightness(99%) contrast(104%);
$filter-green: brightness(0) saturate(100%) invert(43%) sepia(85%) saturate(2583%) hue-rotate(124deg) brightness(102%) contrast(102%);
$filter-red: brightness(0) saturate(100%) invert(38%) sepia(53%) saturate(2048%) hue-rotate(329deg) brightness(106%) contrast(94%);
$filter-yellow: brightness(0) saturate(100%) invert(94%) sepia(28%) saturate(6434%) hue-rotate(336deg) brightness(100%) contrast(99%);
$filter-indigo: brightness(0) saturate(100%) invert(24%) sepia(20%) saturate(591%) hue-rotate(169deg) brightness(96%) contrast(91%);
$filter-indigo-dark: brightness(0) saturate(100%) invert(19%) sepia(12%) saturate(624%) hue-rotate(169deg) brightness(93%) contrast(90%);
