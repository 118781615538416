@import 'src/assets/css/variables.scss';
.row-container {
    width: 100%;
    height: 25px;
    border-radius: 19px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(211, 211, 211);
    list-style-type: none;
}
.rowText {
    max-width: 19.625rem;
    font-family: 'SourceSansPro-Regular';
    font-size: 15px;
    margin: auto;
    color: $color-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 8px;
    @media (max-width: $tablet-grid) {
        max-width: 21.25rem;
    }
}

.rowImg {
    width: 20px;
    height: 18px;
    margin-left: 5px;
    cursor: pointer;
}
.rowImg.small {
    width: 20px;
    height: 18px;
    margin-left: 5px;
}

.rowButton {
    color: $color-black;
    margin-top: 5px;
    margin-right: 12px;
    cursor: pointer;
}