@import 'src/assets/css/variables.scss';

.container-body{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
}

.container-view-contex {
  float: left;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: $tablet-grid) {
  .container-body{
    display: inline-block;
    overflow: hidden;
    margin: auto;
    padding: auto;
  }
  .container-view-contex{
    position: relative;
    width: 100%;
    height: auto;
    // height: 100vh;
    margin: auto auto $bottom-bar-height auto;
    padding: auto;
    overflow: hidden;

    &.no-header {
      margin: auto;
      min-height: calc(100vh - 6px);
    }
  }
}

.mt-10 {
  margin-top: 10px !important;
}

.m-5p {
  margin: 5% !important;
}

.disabled {
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.back-top-button {
  display: none;
}
.back-top-button.toggled {
  display: block;
}
.back-top-button > button {
  height: 70px;
  width: 80px;
  z-index: 50;
  cursor: pointer;
  margin: 40px 0;
  position: fixed;
  border: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}

.back-top-button > button > img {
  width: 100%;
}

.back-top-button > button > i {
  color: linear-gradient(
    to bottom,
    $color-blue,
    $color-blue-dark
  );
  font-size: 70px;
  margin-top: -8px;
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
}
.back-top-button > button:hover,
.back-top-button > button:focus,
.back-top-button > button:active,
.back-top-button > button > i:hover,
.back-top-button > button > i:focus,
.back-top-button > button > i:active {
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.ui.input > input {
  width: 100%;
  margin: 10px auto 10px auto;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  font-family: 'SourceSansPro-Regular';
  font-size: 15px;
  color: black;
  background-color: #ffffff;
}
.ui.input > input:hover,
.ui.input > input:active,
.ui.input > input:focus {
  outline: unset;
}

.ui.dimmer,
.ui.inverted.dimmer {
  &.local {
    background-color: unset;
    position: relative;
    height: 100px;
    z-index: 1;
  }
  &.small {
    background-color: unset;
    position: relative;
    height: 50px;
  }
}
.ui.dimmer > .ui.loader,
.ui.dimmer.small > .ui.loader,
.ui.loader {
  color: $color-black;
  font-family: $font-light;
  line-height: 1.25;
  font-size: 1rem;
  &:after {
    border-color: $color-blue transparent transparent;
  }
}

.ui.dimmer.small > .ui.loader {
  line-height: 1;
  &:after,
  &:before {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.ui.inverted.dimmer > .ui.loader,
.ui.loader {
  color: $color-black;
  &:after {
    border-color: $color-blue transparent transparent;
  }

  &.big {
    font-size: 1.25rem;
  }
}

.required-warning-tooltip {
  position: absolute;
  top: 30px;
  left: 40%;
  float: left;
  opacity: 0;
  width: 0;
}

button {
  cursor: pointer;
}

.ui.checkbox > input:checked~.box:after,
.ui.checkbox > input:checked~label:after,
.ui.checkbox > input:checked~.box:before,
.ui.checkbox > input:checked~label:before,
.ui.checkbox > input:focus:checked~.box:before,
.ui.checkbox > input:focus:checked~label:before {
    color: $color-blue;
    border-color: $color-blue;
  }
.ui.radio.checkbox > input:checked~.box:after,
.ui.radio.checkbox > input:checked~label:after,
.ui.radio.checkbox > input:focus:checked~.box:after,
.ui.radio.checkbox input:focus:checked~label:after {
  background-color: $color-blue;
}
