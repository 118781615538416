@import "src/assets/css/variables.scss";
.card-appointment-main-container {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: $color-blue-extra-light;
  margin-bottom: 32px;
  padding: 8px 4px 18px 8px;
  color: $color-black;
  max-width: 19.625rem;

  &.expanded {
    padding-bottom: 0px;
  }
  @media (max-width: $tablet-grid) {
    max-width: 21.25rem;
  }
}

.card-appointment-info-container {
  display: flex;
  width: 100%;
}
.card-appointment-image-container {
  width: 128px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 4px 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(165, 173, 189, 0.7);
  img {
    width: auto;
    max-width: 128px;
    height: 48px;
    display: flex;
    object-fit: contain;
  }
}

.card-appointment-text-container {
  max-width: 100%;
  line-height: 1.25;
  text-align: left;
}

.card-text-title {
  font-size: 16px;
  //max-height: 40px;
  font-family: "SourceSansPro-Semibold";
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 20px;
  word-break: break-word;
  hyphens: auto;
  &.grey{
    color: $color-grey-blue
  }
}
.card-text-subtitle {
  font-size: 14px;
  font-family: "SourceSansPro-Regular";
  line-height: 18px;
  margin: 6px 0px 0px 0px;
  width: 100%;
  text-align: left;
}

.elements {
  font-family: "SourceSansPro-Light";
}
.elements.padding {
  padding-left: 10px; /* Add left padding */
}

.card-text-dark {
  color: $color-black;
}

.card-appointment-button-container {
  display: flex;
  width: auto;
  height: 60px;
  text-align: center;
  margin: auto 10px auto auto;
  padding-right: 15px;
  cursor: pointer;
}

.card-appointment-not-available {
  background: rgba(234, 237, 243, 0.651);
  position: absolute;
  width: 600px;
  height: 60px;
}
.card-appointment-not-available:hover {
  background: rgba(234, 237, 243, 0.651);
  cursor: initial;
}

.edit-button {
  height: 24px;
  width: 24px;
  background-image: $white-gradient;
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(165, 173, 189, 0.75);
  > img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.card-subtitle-container{
  display: flex;
}

.card-button-container {
  display: flex;
  //align-items: end;
  padding: 8px 8px 5px 4px;
  max-height: 40px;

}
.simple-button {
  height: 16px;
  width: 16px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.link-button{
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: blue; /* Set the desired link color */
}
.smallIcon {
  display: flex;
  padding: 1px 0px 1px 0px;

  //align-items: center;
  &.grey-blue {
  //  filter: invert(66%) sepia(73%) saturate(5914%) hue-rotate(174deg) brightness(105%) contrast(106%);
    filter: $filter-grey-blue
  }
}

.date-container {
  display: flex;
  align-items: flex-start; /* Align items vertically in the center */
//  height: 18px;
  margin: 6px 0px 6px 0px;
}

.dates {
  display: flex;
  flex-direction: column; /* Stack the dates vertically */
  justify-content: center;
  padding: 0px 4px;
  white-space: nowrap;
  overflow: hidden;
  min-width: 52px; //46px;
  &.short { /* This targets elements with class="dates small" */
    min-width: 56px;
    @media (max-width: $tablet-grid) {
      min-width: 66px;
    }
  }
  &.bold {
    font-weight: bold;
  }
}

.strikethrough {
  text-decoration: line-through; /* Strikethrough style */
  color: $color-grey-blue;
}

.failure {
  color: $color-red;
}